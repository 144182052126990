import React, { FC, useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Label, Icon, Confirm, Popup } from 'semantic-ui-react';
import {
  OrganizationRelationshipTypeAssociation,
  useDeleteOrganizationRelationshipMutation,
  useRemoveOrganizationRelationshipTypeAssociationMutation,
} from 'generated/graphql';
import { startCase } from 'lodash';

interface RelationshipTypeAssocationLabelProps {
  relationshipOwnerId: string;
  relationshipPartnerId: string;
  isOwner?: boolean;
  association: OrganizationRelationshipTypeAssociation;
  currentTypeIds: string[];
  refetchFn: () => void;
}

const RelationshipTypeAssocationLabel: FC<RelationshipTypeAssocationLabelProps> = ({
  relationshipOwnerId,
  relationshipPartnerId,
  isOwner,
  association,
  refetchFn,
  currentTypeIds,
}) => {
  const { addToast } = useToasts();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [deleteOrganizationRelationship] = useDeleteOrganizationRelationshipMutation();
  const [removeTypeAssocation] = useRemoveOrganizationRelationshipTypeAssociationMutation();

  const handleCloseModals = () => {
    setShowDeleteModal(false);
    setShowRemoveModal(false);
  };

  const handleSuccess = () => {
    handleCloseModals();
    refetchFn();
  };

  const handleDeleteRelationship = useCallback(() => {
    deleteOrganizationRelationship({
      variables: {
        input: {
          relationshipPartnerId,
          relationshipOwnerId,
        },
      },
      onCompleted(data) {
        const errors = data.deleteOrganizationRelationship?.errors;
        if (errors?.length) {
          addToast(`Error removing relationship: ${errors.map(e => e.message).join(',')}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        handleSuccess();
      },
      onError(error) {
        addToast(`Error removing relationship: ${error.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    });
  }, [
    relationshipOwnerId,
    relationshipPartnerId,
    handleSuccess,
    addToast,
    deleteOrganizationRelationship,
  ]);

  const handleRemoveAssociation = useCallback(() => {
    removeTypeAssocation({
      variables: {
        input: {
          relationshipPartnerId,
          relationshipOwnerId,
          associationId: association.associationId,
        },
      },
      onCompleted(data) {
        const errors = data.removeOrganizationRelationshipTypeAssociation?.errors;
        if (errors?.length) {
          addToast(`Error removing relationship type: ${errors.map(e => e.message).join(',')}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        handleSuccess();
      },
      onError(error) {
        addToast(`Error removing relationship type: ${error.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    });
  }, [
    relationshipOwnerId,
    relationshipPartnerId,
    association.associationId,
    removeTypeAssocation,
    addToast,
  ]);

  const handleDecideAction = () => {
    const remainingTypeIds = currentTypeIds.some(id => id !== association.relationshipType?.id);
    if (!remainingTypeIds) {
      setShowDeleteModal(true);
    } else {
      setShowRemoveModal(true);
    }
  };

  const typeName = startCase(association.relationshipType?.name ?? 'Unknown');

  return (
    <Popup
      content={association.relationshipType?.description ?? 'No description'}
      trigger={
        <Label size="large">
          <Confirm
            open={showDeleteModal}
            onCancel={handleCloseModals}
            onConfirm={handleDeleteRelationship}
            header="Delete relationship"
            content="At least one relationship type is required. If you chose to delete this type it will remove the relationship, but you can add it back later."
            confirmButton="Delete"
          />
          <Confirm
            open={showRemoveModal}
            onCancel={handleCloseModals}
            onConfirm={handleRemoveAssociation}
            header="Remove relationship type"
            content={`This will remove the ${typeName} relationship type.`}
            confirmButton="Remove"
          />
          {typeName} {isOwner && <Icon name="delete" onClick={handleDecideAction} />}
        </Label>
      }
    />
  );
};

export default RelationshipTypeAssocationLabel;
