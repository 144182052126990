import React, { FC, SyntheticEvent, useMemo, useState } from 'react';
import {
  CreateOrganizationRelationshipInput,
  GetOrganizationRelationshipsQuery,
  useCreateOrganizationRelationshipMutation,
  useGetOrganizationsQuery,
  useGetRelationshipTypesQuery,
} from 'generated/graphql';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  Loader,
  Modal,
} from 'semantic-ui-react';
import { notEmpty } from 'types';
import { useToasts } from 'react-toast-notifications';
import { startCase } from 'lodash';

interface AddRelationshipModalProps {
  organization: GetOrganizationRelationshipsQuery['organization'];
  refetchFn: () => void;
}

const AddRelationshipModal: FC<AddRelationshipModalProps> = ({ organization, refetchFn }) => {
  const { addToast } = useToasts();
  const [addRelModalOpen, setAddRelModalOpen] = useState<boolean>(false);
  const relationships = organization.relationships ?? [];
  const [createRelationship, { loading: createLoading }] =
    useCreateOrganizationRelationshipMutation();

  const currentRelationshipOrgIds = useMemo(
    () => relationships.map(r => r.relationshipPartner?.id).filter(notEmpty),
    [relationships, notEmpty]
  );

  const initialFormData: CreateOrganizationRelationshipInput = {
    relationshipOwnerId: organization.id,
    relationshipPartnerId: '',
    relationshipTypeIds: [],
  };

  const [formData, setFormData] = useState<CreateOrganizationRelationshipInput>(initialFormData);

  const {
    data: organizationsData,
    loading: organizationsLoading,
    error: organizationsError,
  } = useGetOrganizationsQuery();

  const {
    data: relTypeData,
    loading: relTypesLoading,
    error: relTypesError,
  } = useGetRelationshipTypesQuery();

  const organizationOptions = useMemo(() => {
    return (
      // remove the current org and any already related orgs from options
      (organizationsData?.organizations ?? [])
        ?.filter(o => !currentRelationshipOrgIds.includes(o.id) && o.id !== organization.id)
        .map<DropdownItemProps>(o => ({
          key: o.id,
          value: o.id,
          text: o.name,
        }))
    );
  }, [organizationsData, currentRelationshipOrgIds]);

  const relationshipTypeOptions = useMemo(() => {
    return (relTypeData?.organizationRelationshipTypes ?? [])
      .filter(notEmpty)
      .map<DropdownItemProps>(t => ({
        key: t.id,
        value: t.id,
        text: startCase(t.name ?? 'Unknown'),
        description: t.description,
      }));
  }, [relTypeData]);

  if (relTypesError || organizationsError) {
    console.error('Error loading form options', { relTypesError, organizationsError });
    addToast('There was an error loading form options', {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const handlePartnerChange = (_e: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setFormData({ ...formData, relationshipPartnerId: value as string });
  };

  const handleRelTypesChange = (_e: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    if (Array.isArray(value)) {
      setFormData({ ...formData, relationshipTypeIds: value as string[] });
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setAddRelModalOpen(false);
  };

  const createErrorToast = (message: string) =>
    addToast(`Error creating relationship: ${message}`, {
      appearance: 'error',
      autoDismiss: true,
    });

  const handleSubmit = () => {
    createRelationship({
      variables: { input: formData },
      onCompleted(data) {
        const errors = data.createOrganizationRelationship?.errors;
        if (errors?.length) {
          createErrorToast(errors.map(e => e.message).join(','));
        } else {
          refetchFn();
          handleClose();
        }
      },
      onError(error) {
        createErrorToast(error.message);
      },
    });
  };

  if (organizationsLoading || relTypesLoading) return <Loader active />;

  const disableSubmit =
    createLoading || !formData.relationshipPartnerId || !formData.relationshipTypeIds.length;

  return (
    <>
      <Modal
        open={addRelModalOpen}
        onClose={handleClose}
        as={Form}
        onSubmit={handleSubmit}
        loading={createLoading}
      >
        <Modal.Header>Add new relationship to {organization.name}</Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Dropdown
              placeholder="Partner Organization"
              fluid
              loading={organizationsLoading}
              search
              selection
              options={organizationOptions}
              value={formData.relationshipPartnerId}
              onChange={handlePartnerChange}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              placeholder="Relationship Types"
              fluid
              loading={relTypesLoading}
              options={relationshipTypeOptions}
              multiple
              selection
              value={formData.relationshipTypeIds}
              onChange={handleRelTypesChange}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={createLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={disableSubmit}>
            Create
          </Button>
        </Modal.Actions>
      </Modal>
      <Button onClick={() => setAddRelModalOpen(true)} floated="right">
        Add Relationship
      </Button>
    </>
  );
};

export default AddRelationshipModal;
