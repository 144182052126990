import React, { useState, useEffect, FC, ReactNode } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Loader } from 'semantic-ui-react';
import { useAuth0 } from '../lib/auth0';

const { API_HOSTNAME = 'http://localhost:5000' } = process.env;

interface AuthorizedApolloProviderProps {
  children: ReactNode;
}

const AuthorizedApolloProvider: FC<AuthorizedApolloProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string>();
  const { getAccessTokenSilently, isLoading } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!isLoading && !token) {
        setToken(await getAccessTokenSilently());
      }
    })();
  }, [isLoading, token]);

  if (isLoading || !token) return <Loader active />;

  const authLink = new ApolloLink((operation, forward) => {
    if (token) {
      const { headers } = operation.getContext();

      operation.setContext({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          '[GraphQL error]: Message:',
          `"${message}"`,
          'Location:',
          locations,
          'Path:',
          path
        );
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const httpLink = createUploadLink({
    uri: `${API_HOSTNAME}/graphql`,
  });

  const client = new ApolloClient({
    // @ts-ignore createUploadLink returns an ApolloLink but it's not fully compatible with the apollo client ApolloLink
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        // don't cache since it may be context-dependent
        OrganizationRecord: {
          keyFields: false,
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
