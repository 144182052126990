import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  _Any: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
  Coordinate2D: { input: any; output: any; }
  Coordinate3D: { input: any; output: any; }
  Cursor: { input: any; output: any; }
  Date: { input: any; output: any; }
  ISOCalendarDate: { input: any; output: any; }
  ISOCalendarDateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Matrix3x3: { input: any; output: any; }
  Matrix4x4: { input: any; output: any; }
  Upload: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

export type AddGlobalReleaseToggleInput = {
  globallyEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type AddGlobalReleaseTogglePayload = {
  __typename?: 'AddGlobalReleaseTogglePayload';
  releaseToggle?: Maybe<ReleaseToggle>;
};

export type AddOrganizationPermissionInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AddOrganizationRelationshipTypeAssociationError = UserInputError;

export type AddOrganizationRelationshipTypeAssociationInput = {
  relationshipOwnerId: Scalars['ID']['input'];
  relationshipPartnerId: Scalars['ID']['input'];
  relationshipTypeId: Scalars['ID']['input'];
};

export type AddOrganizationRelationshipTypeAssociationPayload = {
  __typename?: 'AddOrganizationRelationshipTypeAssociationPayload';
  added?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<AddOrganizationRelationshipTypeAssociationError>>;
};

export type AddOrganizationReleaseToggleInput = {
  id: Scalars['ID']['input'];
  releaseToggle: Scalars['String']['input'];
};

export type AddOrganizationReleaseTogglePayload = {
  __typename?: 'AddOrganizationReleaseTogglePayload';
  organization?: Maybe<Organization>;
};

export type AddUserReleaseToggleInput = {
  id: Scalars['ID']['input'];
  releaseToggle: Scalars['String']['input'];
};

export type AddUserReleaseTogglePayload = {
  __typename?: 'AddUserReleaseTogglePayload';
  user?: Maybe<User>;
};

export type Auth0EnabledOrg = {
  __typename?: 'Auth0EnabledOrg';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Auth0OrganizationStatus = {
  __typename?: 'Auth0OrganizationStatus';
  active?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Auth0User = {
  __typename?: 'Auth0User';
  id?: Maybe<Scalars['ID']['output']>;
  invitedAt?: Maybe<Scalars['String']['output']>;
  mfaEnrollments?: Maybe<Array<MfaEnrollment>>;
  migratedAt?: Maybe<Scalars['String']['output']>;
};

export type CanAccessInProgressInput = {
  canAccessInProgress: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CanReceiveCdnInput = {
  canReceiveCDN: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateOrganizationInput = {
  mfa?: InputMaybe<OrganizationMfaInput>;
  name: Scalars['String']['input'];
  sfId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type CreateOrganizationRelationshipError = UserInputError;

export type CreateOrganizationRelationshipInput = {
  relationshipOwnerId: Scalars['ID']['input'];
  relationshipPartnerId: Scalars['ID']['input'];
  relationshipTypeIds: Array<Scalars['ID']['input']>;
};

export type CreateOrganizationRelationshipPayload = {
  __typename?: 'CreateOrganizationRelationshipPayload';
  created?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<CreateOrganizationRelationshipError>>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  groups: Array<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  organizations: Array<OrganizationRecordInput>;
};

export type DeleteGlobalReleaseToggleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteGlobalReleaseTogglePayload = {
  __typename?: 'DeleteGlobalReleaseTogglePayload';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteOrganizationRelationshipError = UserInputError;

export type DeleteOrganizationRelationshipInput = {
  relationshipOwnerId: Scalars['ID']['input'];
  relationshipPartnerId: Scalars['ID']['input'];
};

export type DeleteOrganizationRelationshipPayload = {
  __typename?: 'DeleteOrganizationRelationshipPayload';
  deleted?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<DeleteOrganizationRelationshipError>>;
};

export type DeleteOrganizationReleaseToggleInput = {
  id: Scalars['ID']['input'];
  releaseToggle: Scalars['String']['input'];
};

export type DeleteOrganizationReleaseTogglePayload = {
  __typename?: 'DeleteOrganizationReleaseTogglePayload';
  organization?: Maybe<Organization>;
};

export type DeleteUserReleaseToggleInput = {
  id: Scalars['ID']['input'];
  releaseToggle: Scalars['String']['input'];
};

export type DeleteUserReleaseTogglePayload = {
  __typename?: 'DeleteUserReleaseTogglePayload';
  user?: Maybe<User>;
};

export type Error = {
  message: Scalars['String']['output'];
};

export type FilterBy = {
  key: Scalars['String']['input'];
  operator?: InputMaybe<FilterOperator>;
  values: Array<InputMaybe<Scalars['String']['input']>>;
};

export enum FilterOperator {
  ArrayIncludes = 'ARRAY_INCLUDES',
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  NotEquals = 'NOT_EQUALS'
}

export type Group = {
  __typename?: 'Group';
  name: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID']['output'];
  locationCity?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  locationSlug?: Maybe<Scalars['String']['output']>;
  locationState?: Maybe<Scalars['String']['output']>;
  locationZip?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
};

export type MfaEnrollment = {
  __typename?: 'MFAEnrollment';
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addGlobalReleaseToggle?: Maybe<AddGlobalReleaseTogglePayload>;
  addOrganizationPermission: User;
  addOrganizationRelationshipTypeAssociation?: Maybe<AddOrganizationRelationshipTypeAssociationPayload>;
  addOrganizationReleaseToggle?: Maybe<AddOrganizationReleaseTogglePayload>;
  addUserReleaseToggle?: Maybe<AddUserReleaseTogglePayload>;
  createOrganization: Organization;
  createOrganizationRelationship?: Maybe<CreateOrganizationRelationshipPayload>;
  createUser: User;
  deleteGlobalReleaseToggle?: Maybe<DeleteGlobalReleaseTogglePayload>;
  deleteLogo: Organization;
  deleteOrganizationRelationship?: Maybe<DeleteOrganizationRelationshipPayload>;
  deleteOrganizationReleaseToggle?: Maybe<DeleteOrganizationReleaseTogglePayload>;
  deleteUser: Scalars['Boolean']['output'];
  deleteUserReleaseToggle?: Maybe<DeleteUserReleaseTogglePayload>;
  disableAuth0ForOrganization: Auth0OrganizationStatus;
  disableUser: Scalars['Boolean']['output'];
  enableAuth0ForOrganization: Auth0OrganizationStatus;
  enableUser: Scalars['Boolean']['output'];
  removeOrganizationPermission: User;
  removeOrganizationRelationshipTypeAssociation?: Maybe<RemoveOrganizationRelationshipTypeAssociationPayload>;
  removeUserMFAEnrollment?: Maybe<RemoveUserMfaEnrollmentPayload>;
  sendPasswordResetEmail: Scalars['Boolean']['output'];
  setCanAccessInProgress: User;
  setCanReceiveCDN: User;
  setFeatures: Array<Scalars['String']['output']>;
  setOrganizationName?: Maybe<SetOrganizationNamePayload>;
  setOrganizationSfId?: Maybe<SetOrganizationSfIdPayload>;
  setUserBaseOrg: User;
  setUserCanUseDevTools?: Maybe<SetUserCanUseDevToolsPayload>;
  setUserEmail: User;
  setUserFeatures: Array<Scalars['String']['output']>;
  setUserFirstLastName: User;
  setUserGroups: User;
  setUserLocations: User;
  setUserOrgExpiration: User;
  setUserOrgNiaaRole?: Maybe<SetUserOrgNiaaRolePayload>;
  setUserOrgRole: User;
  setUserPassword: Scalars['String']['output'];
  toggleGlobalReleaseToggle?: Maybe<ToggleGlobalReleaseTogglePayload>;
  updateMFAForOrganization?: Maybe<UpdateMfaForOrganizationPayload>;
  uploadLogo: Organization;
};


export type MutationAddGlobalReleaseToggleArgs = {
  input: AddGlobalReleaseToggleInput;
};


export type MutationAddOrganizationPermissionArgs = {
  input: AddOrganizationPermissionInput;
};


export type MutationAddOrganizationRelationshipTypeAssociationArgs = {
  input: AddOrganizationRelationshipTypeAssociationInput;
};


export type MutationAddOrganizationReleaseToggleArgs = {
  input: AddOrganizationReleaseToggleInput;
};


export type MutationAddUserReleaseToggleArgs = {
  input: AddUserReleaseToggleInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationRelationshipArgs = {
  input: CreateOrganizationRelationshipInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteGlobalReleaseToggleArgs = {
  input: DeleteGlobalReleaseToggleInput;
};


export type MutationDeleteLogoArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationRelationshipArgs = {
  input: DeleteOrganizationRelationshipInput;
};


export type MutationDeleteOrganizationReleaseToggleArgs = {
  input: DeleteOrganizationReleaseToggleInput;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserReleaseToggleArgs = {
  input: DeleteUserReleaseToggleInput;
};


export type MutationDisableAuth0ForOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisableUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEnableAuth0ForOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEnableUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveOrganizationPermissionArgs = {
  input: RemoveOrganizationPermissionInput;
};


export type MutationRemoveOrganizationRelationshipTypeAssociationArgs = {
  input: RemoveOrganizationRelationshipTypeAssociationInput;
};


export type MutationRemoveUserMfaEnrollmentArgs = {
  input: RemoveUserMfaEnrollmentInput;
};


export type MutationSendPasswordResetEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetCanAccessInProgressArgs = {
  input: CanAccessInProgressInput;
};


export type MutationSetCanReceiveCdnArgs = {
  input: CanReceiveCdnInput;
};


export type MutationSetFeaturesArgs = {
  features: Array<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationSetOrganizationNameArgs = {
  input: SetOrganizationNameInput;
};


export type MutationSetOrganizationSfIdArgs = {
  input: SetOrganizationSfIdInput;
};


export type MutationSetUserBaseOrgArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationSetUserCanUseDevToolsArgs = {
  input: SetUserCanUseDevToolsInput;
};


export type MutationSetUserEmailArgs = {
  input: SetUserEmailInput;
};


export type MutationSetUserFeaturesArgs = {
  features: Array<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationSetUserFirstLastNameArgs = {
  input: SetUserFirstLastNameInput;
};


export type MutationSetUserGroupsArgs = {
  input: SetUserGroupsInput;
};


export type MutationSetUserLocationsArgs = {
  input: SetUserLocationsInput;
};


export type MutationSetUserOrgExpirationArgs = {
  input: SetUserOrgExpirationInput;
};


export type MutationSetUserOrgNiaaRoleArgs = {
  input: SetUserOrgNiaaRoleInput;
};


export type MutationSetUserOrgRoleArgs = {
  input: SetUserOrgRoleInput;
};


export type MutationSetUserPasswordArgs = {
  id: Scalars['ID']['input'];
};


export type MutationToggleGlobalReleaseToggleArgs = {
  input: ToggleGlobalReleaseToggleInput;
};


export type MutationUpdateMfaForOrganizationArgs = {
  input: UpdateMfaForOrganizationInput;
};


export type MutationUploadLogoArgs = {
  id: Scalars['ID']['input'];
  image: Scalars['Upload']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  locations: Array<Location>;
  logoLink?: Maybe<Scalars['String']['output']>;
  mfa?: Maybe<OrganizationMfa>;
  name: Scalars['String']['output'];
  relationships?: Maybe<Array<OrganizationRelationship>>;
  releaseToggles?: Maybe<Array<Scalars['String']['output']>>;
  sfId?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};


export type OrganizationLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationMfa = {
  __typename?: 'OrganizationMFA';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  rememberDevice?: Maybe<Scalars['Boolean']['output']>;
};

export type OrganizationMfaInput = {
  enabled: Scalars['Boolean']['input'];
  rememberDevice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationRecord = {
  __typename?: 'OrganizationRecord';
  canAccessInProgress: Scalars['Boolean']['output'];
  canReceiveCDN?: Maybe<Scalars['Boolean']['output']>;
  expires?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isBaseOrganization: Scalars['Boolean']['output'];
  locations: Array<Location>;
  mfa?: Maybe<OrganizationMfa>;
  name: Scalars['String']['output'];
  niaaRoleId?: Maybe<Scalars['String']['output']>;
  roleId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type OrganizationRecordInput = {
  canAccessInProgress: Scalars['Boolean']['input'];
  isBaseOrganization: Scalars['Boolean']['input'];
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId: Scalars['ID']['input'];
  roleId: Scalars['String']['input'];
};

export type OrganizationRelationship = {
  __typename?: 'OrganizationRelationship';
  relationshipOwner?: Maybe<Organization>;
  relationshipPartner?: Maybe<Organization>;
  relationshipTypes?: Maybe<Array<OrganizationRelationshipTypeAssociation>>;
  scope?: Maybe<OrganizationRelationshipScope>;
};

export enum OrganizationRelationshipScope {
  RelationshipOwner = 'RELATIONSHIP_OWNER',
  RelationshipPartner = 'RELATIONSHIP_PARTNER'
}

export type OrganizationRelationshipType = {
  __typename?: 'OrganizationRelationshipType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type OrganizationRelationshipTypeAssociation = {
  __typename?: 'OrganizationRelationshipTypeAssociation';
  associationId: Scalars['String']['output'];
  relationshipType?: Maybe<OrganizationRelationshipType>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  account: Scalars['String']['output'];
  auth0EnabledOrganizations?: Maybe<Array<Auth0EnabledOrg>>;
  globalReleaseToggles?: Maybe<Array<ReleaseToggle>>;
  organization: Organization;
  organizationAuth0Status: Auth0OrganizationStatus;
  organizationRelationshipTypes?: Maybe<Array<OrganizationRelationshipType>>;
  organizations: Array<Organization>;
  userByEmail: User;
  userById: User;
  userEmails?: Maybe<Array<Scalars['String']['output']>>;
  userGroups: Array<Group>;
  userNiaaRoles: Array<Role>;
  userRoles: Array<Role>;
  usersByOrganizationId?: Maybe<UsersConnection>;
};


export type QueryGlobalReleaseTogglesArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationAuth0StatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserEmailsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersByOrganizationIdArgs = {
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type ReleaseToggle = {
  __typename?: 'ReleaseToggle';
  globallyEnabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RemoveOrganizationPermissionInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveOrganizationRelationshipTypeAssociationError = UserInputError;

export type RemoveOrganizationRelationshipTypeAssociationInput = {
  associationId: Scalars['ID']['input'];
  relationshipOwnerId: Scalars['ID']['input'];
  relationshipPartnerId: Scalars['ID']['input'];
};

export type RemoveOrganizationRelationshipTypeAssociationPayload = {
  __typename?: 'RemoveOrganizationRelationshipTypeAssociationPayload';
  errors?: Maybe<Array<RemoveOrganizationRelationshipTypeAssociationError>>;
  removed?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveUserMfaEnrollmentInput = {
  enrollmentId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveUserMfaEnrollmentPayload = {
  __typename?: 'RemoveUserMFAEnrollmentPayload';
  user?: Maybe<User>;
};

export type Role = {
  __typename?: 'Role';
  isDeprecated?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type SetOrganizationNameInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SetOrganizationNamePayload = {
  __typename?: 'SetOrganizationNamePayload';
  organization?: Maybe<Organization>;
};

export type SetOrganizationSfIdInput = {
  id: Scalars['ID']['input'];
  sfId: Scalars['String']['input'];
};

export type SetOrganizationSfIdPayload = {
  __typename?: 'SetOrganizationSfIdPayload';
  organization?: Maybe<Organization>;
};

export type SetUserCanUseDevToolsInput = {
  canUseDevTools: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type SetUserCanUseDevToolsPayload = {
  __typename?: 'SetUserCanUseDevToolsPayload';
  user?: Maybe<User>;
};

export type SetUserEmailInput = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type SetUserFirstLastNameInput = {
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
};

export type SetUserGroupsInput = {
  groups: Array<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type SetUserLocationsInput = {
  id: Scalars['ID']['input'];
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationId: Scalars['ID']['input'];
};

export type SetUserOrgExpirationInput = {
  expires: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type SetUserOrgNiaaRoleInput = {
  organizationId: Scalars['String']['input'];
  roleId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type SetUserOrgNiaaRolePayload = {
  __typename?: 'SetUserOrgNiaaRolePayload';
  user?: Maybe<User>;
};

export type SetUserOrgRoleInput = {
  organizationId: Scalars['String']['input'];
  roleId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type SortBy = {
  key: Scalars['String']['input'];
  sort?: InputMaybe<SortDirection>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ToggleGlobalReleaseToggleInput = {
  id: Scalars['ID']['input'];
};

export type ToggleGlobalReleaseTogglePayload = {
  __typename?: 'ToggleGlobalReleaseTogglePayload';
  releaseToggle?: Maybe<ReleaseToggle>;
};

export type UpdateMfaForOrganizationInput = {
  id: Scalars['ID']['input'];
  mfa: OrganizationMfaInput;
};

export type UpdateMfaForOrganizationPayload = {
  __typename?: 'UpdateMFAForOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type User = {
  __typename?: 'User';
  auth0?: Maybe<Auth0User>;
  canUseDevTools: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Group>>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  organizations: Array<Maybe<OrganizationRecord>>;
  releaseToggles?: Maybe<Array<Scalars['String']['output']>>;
  status: Scalars['String']['output'];
  temporaryPassword?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type UserInputError = Error & {
  __typename?: 'UserInputError';
  message: Scalars['String']['output'];
  path?: Maybe<Array<Scalars['String']['output']>>;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type AddOrganizationRelationshipTypeAssociationMutationVariables = Exact<{
  input: AddOrganizationRelationshipTypeAssociationInput;
}>;


export type AddOrganizationRelationshipTypeAssociationMutation = { __typename?: 'Mutation', addOrganizationRelationshipTypeAssociation?: { __typename?: 'AddOrganizationRelationshipTypeAssociationPayload', added?: boolean | null, errors?: Array<{ __typename?: 'UserInputError', message: string }> | null } | null };

export type CreateOrganizationRelationshipMutationVariables = Exact<{
  input: CreateOrganizationRelationshipInput;
}>;


export type CreateOrganizationRelationshipMutation = { __typename?: 'Mutation', createOrganizationRelationship?: { __typename?: 'CreateOrganizationRelationshipPayload', created?: boolean | null, errors?: Array<{ __typename?: 'UserInputError', message: string }> | null } | null };

export type DeleteOrganizationRelationshipMutationVariables = Exact<{
  input: DeleteOrganizationRelationshipInput;
}>;


export type DeleteOrganizationRelationshipMutation = { __typename?: 'Mutation', deleteOrganizationRelationship?: { __typename?: 'DeleteOrganizationRelationshipPayload', deleted?: boolean | null, errors?: Array<{ __typename?: 'UserInputError', message: string }> | null } | null };

export type GetOrganizationRelationshipsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetOrganizationRelationshipsQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, name: string, relationships?: Array<{ __typename?: 'OrganizationRelationship', scope?: OrganizationRelationshipScope | null, relationshipOwner?: { __typename?: 'Organization', id: string, name: string } | null, relationshipPartner?: { __typename?: 'Organization', id: string, name: string } | null, relationshipTypes?: Array<{ __typename?: 'OrganizationRelationshipTypeAssociation', associationId: string, relationshipType?: { __typename?: 'OrganizationRelationshipType', id: string, name?: string | null, description?: string | null } | null }> | null }> | null } };

export type GetRelationshipTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRelationshipTypesQuery = { __typename?: 'Query', organizationRelationshipTypes?: Array<{ __typename?: 'OrganizationRelationshipType', id: string, name?: string | null, description?: string | null }> | null };

export type GetUsersByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUsersByOrganizationIdQuery = { __typename?: 'Query', usersByOrganizationId?: { __typename?: 'UsersConnection', nodes: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, enabled: boolean, organizations: Array<{ __typename?: 'OrganizationRecord', name: string, roleId: string, isBaseOrganization: boolean, organizationId: string, locations: Array<{ __typename?: 'Location', id: string, locationName?: string | null }> } | null> }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, totalCount?: number | null } } | null };

export type RemoveOrganizationRelationshipTypeAssociationMutationVariables = Exact<{
  input: RemoveOrganizationRelationshipTypeAssociationInput;
}>;


export type RemoveOrganizationRelationshipTypeAssociationMutation = { __typename?: 'Mutation', removeOrganizationRelationshipTypeAssociation?: { __typename?: 'RemoveOrganizationRelationshipTypeAssociationPayload', removed?: boolean | null, errors?: Array<{ __typename?: 'UserInputError', message: string }> | null } | null };

export type AddOrganizationReleaseToggleMutationVariables = Exact<{
  input: AddOrganizationReleaseToggleInput;
}>;


export type AddOrganizationReleaseToggleMutation = { __typename?: 'Mutation', addOrganizationReleaseToggle?: { __typename?: 'AddOrganizationReleaseTogglePayload', organization?: { __typename?: 'Organization', id: string } | null } | null };

export type DeleteLogoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteLogoMutation = { __typename?: 'Mutation', deleteLogo: { __typename?: 'Organization', id: string, logoLink?: string | null } };

export type DeleteOrganizationReleaseToggleMutationVariables = Exact<{
  input: DeleteOrganizationReleaseToggleInput;
}>;


export type DeleteOrganizationReleaseToggleMutation = { __typename?: 'Mutation', deleteOrganizationReleaseToggle?: { __typename?: 'DeleteOrganizationReleaseTogglePayload', organization?: { __typename?: 'Organization', id: string } | null } | null };

export type DisableAuth0ForOrgMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DisableAuth0ForOrgMutation = { __typename?: 'Mutation', disableAuth0ForOrganization: { __typename?: 'Auth0OrganizationStatus', active?: boolean | null } };

export type EnableAuth0ForOrgMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EnableAuth0ForOrgMutation = { __typename?: 'Mutation', enableAuth0ForOrganization: { __typename?: 'Auth0OrganizationStatus', active?: boolean | null } };

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, name: string, slug: string, mfa?: { __typename?: 'OrganizationMFA', enabled?: boolean | null, rememberDevice?: boolean | null } | null, locations: Array<{ __typename?: 'Location', id: string, locationSlug?: string | null, locationName?: string | null }> } };

export type GetOrganizationByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetOrganizationByIdQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, name: string, slug: string, sfId?: string | null, features?: Array<string | null> | null, releaseToggles?: Array<string> | null, mfa?: { __typename?: 'OrganizationMFA', enabled?: boolean | null, rememberDevice?: boolean | null } | null } };

export type GetOrganizationLocationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetOrganizationLocationsQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, locations: Array<{ __typename?: 'Location', id: string, locationName?: string | null, locationSlug?: string | null }> } };

export type GetOrganizationLogoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetOrganizationLogoQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, logoLink?: string | null } };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string, slug: string, sfId?: string | null, mfa?: { __typename?: 'OrganizationMFA', enabled?: boolean | null, rememberDevice?: boolean | null } | null }> };

export type SetFeaturesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  features: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SetFeaturesMutation = { __typename?: 'Mutation', setFeatures: Array<string> };

export type SetOrganizationNameMutationVariables = Exact<{
  input: SetOrganizationNameInput;
}>;


export type SetOrganizationNameMutation = { __typename?: 'Mutation', setOrganizationName?: { __typename?: 'SetOrganizationNamePayload', organization?: { __typename?: 'Organization', id: string, name: string } | null } | null };

export type SetOrganizationSfIdMutationVariables = Exact<{
  input: SetOrganizationSfIdInput;
}>;


export type SetOrganizationSfIdMutation = { __typename?: 'Mutation', setOrganizationSfId?: { __typename?: 'SetOrganizationSfIdPayload', organization?: { __typename?: 'Organization', id: string, name: string, sfId?: string | null } | null } | null };

export type UpdateMfaForOrganizationMutationVariables = Exact<{
  input: UpdateMfaForOrganizationInput;
}>;


export type UpdateMfaForOrganizationMutation = { __typename?: 'Mutation', updateMFAForOrganization?: { __typename?: 'UpdateMFAForOrganizationPayload', organization?: { __typename?: 'Organization', id: string, mfa?: { __typename?: 'OrganizationMFA', enabled?: boolean | null, rememberDevice?: boolean | null } | null } | null } | null };

export type UploadLogoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
}>;


export type UploadLogoMutation = { __typename?: 'Mutation', uploadLogo: { __typename?: 'Organization', id: string, logoLink?: string | null } };

export type AddGlobalReleaseToggleMutationVariables = Exact<{
  input: AddGlobalReleaseToggleInput;
}>;


export type AddGlobalReleaseToggleMutation = { __typename?: 'Mutation', addGlobalReleaseToggle?: { __typename?: 'AddGlobalReleaseTogglePayload', releaseToggle?: { __typename?: 'ReleaseToggle', id: string, name: string, globallyEnabled?: boolean | null } | null } | null };

export type DeleteGlobalReleaseToggleMutationVariables = Exact<{
  input: DeleteGlobalReleaseToggleInput;
}>;


export type DeleteGlobalReleaseToggleMutation = { __typename?: 'Mutation', deleteGlobalReleaseToggle?: { __typename?: 'DeleteGlobalReleaseTogglePayload', success?: boolean | null } | null };

export type ReleaseToggleFieldsFragment = { __typename?: 'ReleaseToggle', id: string, name: string, globallyEnabled?: boolean | null };

export type GetGlobalReleaseTogglesQueryVariables = Exact<{
  names?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type GetGlobalReleaseTogglesQuery = { __typename?: 'Query', globalReleaseToggles?: Array<{ __typename?: 'ReleaseToggle', id: string, name: string, globallyEnabled?: boolean | null }> | null };

export type ToggleGlobalReleaseToggleMutationVariables = Exact<{
  input: ToggleGlobalReleaseToggleInput;
}>;


export type ToggleGlobalReleaseToggleMutation = { __typename?: 'Mutation', toggleGlobalReleaseToggle?: { __typename?: 'ToggleGlobalReleaseTogglePayload', releaseToggle?: { __typename?: 'ReleaseToggle', id: string, name: string, globallyEnabled?: boolean | null } | null } | null };

export type AddOrganizationPermissionMutationVariables = Exact<{
  input: AddOrganizationPermissionInput;
}>;


export type AddOrganizationPermissionMutation = { __typename?: 'Mutation', addOrganizationPermission: { __typename?: 'User', id: string } };

export type AddUserReleaseToggleMutationVariables = Exact<{
  input: AddUserReleaseToggleInput;
}>;


export type AddUserReleaseToggleMutation = { __typename?: 'Mutation', addUserReleaseToggle?: { __typename?: 'AddUserReleaseTogglePayload', user?: { __typename?: 'User', id: string } | null } | null };

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, email?: string | null, status: string, enabled: boolean, lastName?: string | null, firstName?: string | null, createdAt?: string | null, updatedAt?: string | null, temporaryPassword?: string | null, groups?: Array<{ __typename?: 'Group', name: string }> | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type DeleteUserReleaseToggleMutationVariables = Exact<{
  input: DeleteUserReleaseToggleInput;
}>;


export type DeleteUserReleaseToggleMutation = { __typename?: 'Mutation', deleteUserReleaseToggle?: { __typename?: 'DeleteUserReleaseTogglePayload', user?: { __typename?: 'User', id: string } | null } | null };

export type DisableUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUser: boolean };

export type EnableUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EnableUserMutation = { __typename?: 'Mutation', enableUser: boolean };

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UserByEmailQuery = { __typename?: 'Query', userByEmail: { __typename?: 'User', id: string, email?: string | null, status: string, enabled: boolean, lastName?: string | null, firstName?: string | null, createdAt?: string | null, updatedAt?: string | null, features?: Array<string | null> | null, canUseDevTools: boolean, releaseToggles?: Array<string> | null, auth0?: { __typename?: 'Auth0User', id?: string | null, migratedAt?: string | null, invitedAt?: string | null, mfaEnrollments?: Array<{ __typename?: 'MFAEnrollment', id: string, status?: string | null, type?: string | null }> | null } | null, groups?: Array<{ __typename?: 'Group', name: string }> | null, organizations: Array<{ __typename?: 'OrganizationRecord', id: string, name: string, slug: string, roleId: string, niaaRoleId?: string | null, isBaseOrganization: boolean, canAccessInProgress: boolean, canReceiveCDN?: boolean | null, expires?: number | null, mfa?: { __typename?: 'OrganizationMFA', enabled?: boolean | null, rememberDevice?: boolean | null } | null, locations: Array<{ __typename?: 'Location', id: string, locationCity?: string | null, locationName?: string | null, locationSlug?: string | null, locationState?: string | null, locationZip?: string | null }> } | null> } };

export type GetUserEmailsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserEmailsQuery = { __typename?: 'Query', userEmails?: Array<string> | null };

export type GetUserGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserGroupsQuery = { __typename?: 'Query', userGroups: Array<{ __typename?: 'Group', name: string }> };

export type GetUserNiaaRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserNiaaRolesQuery = { __typename?: 'Query', userNiaaRoles: Array<{ __typename?: 'Role', name: string, isDeprecated?: boolean | null }> };

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRolesQuery = { __typename?: 'Query', userRoles: Array<{ __typename?: 'Role', name: string, isDeprecated?: boolean | null }> };

export type RemoveOrganizationPermissionMutationVariables = Exact<{
  input: RemoveOrganizationPermissionInput;
}>;


export type RemoveOrganizationPermissionMutation = { __typename?: 'Mutation', removeOrganizationPermission: { __typename?: 'User', id: string } };

export type RemoveUserMfaEnrollmentMutationVariables = Exact<{
  input: RemoveUserMfaEnrollmentInput;
}>;


export type RemoveUserMfaEnrollmentMutation = { __typename?: 'Mutation', removeUserMFAEnrollment?: { __typename?: 'RemoveUserMFAEnrollmentPayload', user?: { __typename?: 'User', id: string } | null } | null };

export type SetUserBaseOrgMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
}>;


export type SetUserBaseOrgMutation = { __typename?: 'Mutation', setUserBaseOrg: { __typename?: 'User', id: string } };

export type SetCanAccessInProgressMutationVariables = Exact<{
  input: CanAccessInProgressInput;
}>;


export type SetCanAccessInProgressMutation = { __typename?: 'Mutation', setCanAccessInProgress: { __typename?: 'User', id: string } };

export type UpdateCanReceiveCdnMutationVariables = Exact<{
  input: CanReceiveCdnInput;
}>;


export type UpdateCanReceiveCdnMutation = { __typename?: 'Mutation', setCanReceiveCDN: { __typename?: 'User', id: string } };

export type SetCanUseDevToolsMutationVariables = Exact<{
  input: SetUserCanUseDevToolsInput;
}>;


export type SetCanUseDevToolsMutation = { __typename?: 'Mutation', setUserCanUseDevTools?: { __typename?: 'SetUserCanUseDevToolsPayload', user?: { __typename?: 'User', id: string } | null } | null };

export type SetUserEmailMutationVariables = Exact<{
  input: SetUserEmailInput;
}>;


export type SetUserEmailMutation = { __typename?: 'Mutation', setUserEmail: { __typename?: 'User', id: string } };

export type SetUserFeaturesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  features: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SetUserFeaturesMutation = { __typename?: 'Mutation', setUserFeatures: Array<string> };

export type SetUserFirstLastNameMutationVariables = Exact<{
  input: SetUserFirstLastNameInput;
}>;


export type SetUserFirstLastNameMutation = { __typename?: 'Mutation', setUserFirstLastName: { __typename?: 'User', id: string } };

export type SetUserGroupsMutationVariables = Exact<{
  input: SetUserGroupsInput;
}>;


export type SetUserGroupsMutation = { __typename?: 'Mutation', setUserGroups: { __typename?: 'User', groups?: Array<{ __typename?: 'Group', name: string }> | null } };

export type SetUserLocationsMutationVariables = Exact<{
  input: SetUserLocationsInput;
}>;


export type SetUserLocationsMutation = { __typename?: 'Mutation', setUserLocations: { __typename?: 'User', id: string } };

export type SetUserOrgExpirationMutationVariables = Exact<{
  input: SetUserOrgExpirationInput;
}>;


export type SetUserOrgExpirationMutation = { __typename?: 'Mutation', setUserOrgExpiration: { __typename?: 'User', organizations: Array<{ __typename?: 'OrganizationRecord', expires?: number | null } | null> } };

export type SetUserOrgNiaaRoleMutationVariables = Exact<{
  input: SetUserOrgNiaaRoleInput;
}>;


export type SetUserOrgNiaaRoleMutation = { __typename?: 'Mutation', setUserOrgNiaaRole?: { __typename?: 'SetUserOrgNiaaRolePayload', user?: { __typename?: 'User', organizations: Array<{ __typename?: 'OrganizationRecord', niaaRoleId?: string | null } | null> } | null } | null };

export type SetUserOrgRoleMutationVariables = Exact<{
  input: SetUserOrgRoleInput;
}>;


export type SetUserOrgRoleMutation = { __typename?: 'Mutation', setUserOrgRole: { __typename?: 'User', organizations: Array<{ __typename?: 'OrganizationRecord', roleId: string } | null> } };

export type GetAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountQuery = { __typename?: 'Query', account: string };

export const ReleaseToggleFieldsFragmentDoc = gql`
    fragment ReleaseToggleFields on ReleaseToggle {
  id
  name
  globallyEnabled
}
    `;
export const AddOrganizationRelationshipTypeAssociationDocument = gql`
    mutation AddOrganizationRelationshipTypeAssociation($input: AddOrganizationRelationshipTypeAssociationInput!) {
  addOrganizationRelationshipTypeAssociation(input: $input) {
    added
    errors {
      ... on UserInputError {
        message
      }
    }
  }
}
    `;
export type AddOrganizationRelationshipTypeAssociationMutationFn = Apollo.MutationFunction<AddOrganizationRelationshipTypeAssociationMutation, AddOrganizationRelationshipTypeAssociationMutationVariables>;

/**
 * __useAddOrganizationRelationshipTypeAssociationMutation__
 *
 * To run a mutation, you first call `useAddOrganizationRelationshipTypeAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationRelationshipTypeAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationRelationshipTypeAssociationMutation, { data, loading, error }] = useAddOrganizationRelationshipTypeAssociationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationRelationshipTypeAssociationMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationRelationshipTypeAssociationMutation, AddOrganizationRelationshipTypeAssociationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationRelationshipTypeAssociationMutation, AddOrganizationRelationshipTypeAssociationMutationVariables>(AddOrganizationRelationshipTypeAssociationDocument, options);
      }
export type AddOrganizationRelationshipTypeAssociationMutationHookResult = ReturnType<typeof useAddOrganizationRelationshipTypeAssociationMutation>;
export type AddOrganizationRelationshipTypeAssociationMutationResult = Apollo.MutationResult<AddOrganizationRelationshipTypeAssociationMutation>;
export type AddOrganizationRelationshipTypeAssociationMutationOptions = Apollo.BaseMutationOptions<AddOrganizationRelationshipTypeAssociationMutation, AddOrganizationRelationshipTypeAssociationMutationVariables>;
export const CreateOrganizationRelationshipDocument = gql`
    mutation CreateOrganizationRelationship($input: CreateOrganizationRelationshipInput!) {
  createOrganizationRelationship(input: $input) {
    errors {
      ... on UserInputError {
        message
      }
    }
    created
  }
}
    `;
export type CreateOrganizationRelationshipMutationFn = Apollo.MutationFunction<CreateOrganizationRelationshipMutation, CreateOrganizationRelationshipMutationVariables>;

/**
 * __useCreateOrganizationRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationRelationshipMutation, { data, loading, error }] = useCreateOrganizationRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationRelationshipMutation, CreateOrganizationRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationRelationshipMutation, CreateOrganizationRelationshipMutationVariables>(CreateOrganizationRelationshipDocument, options);
      }
export type CreateOrganizationRelationshipMutationHookResult = ReturnType<typeof useCreateOrganizationRelationshipMutation>;
export type CreateOrganizationRelationshipMutationResult = Apollo.MutationResult<CreateOrganizationRelationshipMutation>;
export type CreateOrganizationRelationshipMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationRelationshipMutation, CreateOrganizationRelationshipMutationVariables>;
export const DeleteOrganizationRelationshipDocument = gql`
    mutation DeleteOrganizationRelationship($input: DeleteOrganizationRelationshipInput!) {
  deleteOrganizationRelationship(input: $input) {
    errors {
      ... on UserInputError {
        message
      }
    }
    deleted
  }
}
    `;
export type DeleteOrganizationRelationshipMutationFn = Apollo.MutationFunction<DeleteOrganizationRelationshipMutation, DeleteOrganizationRelationshipMutationVariables>;

/**
 * __useDeleteOrganizationRelationshipMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationRelationshipMutation, { data, loading, error }] = useDeleteOrganizationRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationRelationshipMutation, DeleteOrganizationRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationRelationshipMutation, DeleteOrganizationRelationshipMutationVariables>(DeleteOrganizationRelationshipDocument, options);
      }
export type DeleteOrganizationRelationshipMutationHookResult = ReturnType<typeof useDeleteOrganizationRelationshipMutation>;
export type DeleteOrganizationRelationshipMutationResult = Apollo.MutationResult<DeleteOrganizationRelationshipMutation>;
export type DeleteOrganizationRelationshipMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationRelationshipMutation, DeleteOrganizationRelationshipMutationVariables>;
export const GetOrganizationRelationshipsDocument = gql`
    query GetOrganizationRelationships($id: ID!) {
  organization(id: $id) {
    id
    name
    relationships {
      relationshipOwner {
        id
        name
      }
      relationshipPartner {
        id
        name
      }
      scope
      relationshipTypes {
        associationId
        relationshipType {
          id
          name
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationRelationshipsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationRelationshipsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationRelationshipsQuery, GetOrganizationRelationshipsQueryVariables> & ({ variables: GetOrganizationRelationshipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationRelationshipsQuery, GetOrganizationRelationshipsQueryVariables>(GetOrganizationRelationshipsDocument, options);
      }
export function useGetOrganizationRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationRelationshipsQuery, GetOrganizationRelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationRelationshipsQuery, GetOrganizationRelationshipsQueryVariables>(GetOrganizationRelationshipsDocument, options);
        }
export function useGetOrganizationRelationshipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationRelationshipsQuery, GetOrganizationRelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationRelationshipsQuery, GetOrganizationRelationshipsQueryVariables>(GetOrganizationRelationshipsDocument, options);
        }
export type GetOrganizationRelationshipsQueryHookResult = ReturnType<typeof useGetOrganizationRelationshipsQuery>;
export type GetOrganizationRelationshipsLazyQueryHookResult = ReturnType<typeof useGetOrganizationRelationshipsLazyQuery>;
export type GetOrganizationRelationshipsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationRelationshipsSuspenseQuery>;
export type GetOrganizationRelationshipsQueryResult = Apollo.QueryResult<GetOrganizationRelationshipsQuery, GetOrganizationRelationshipsQueryVariables>;
export const GetRelationshipTypesDocument = gql`
    query GetRelationshipTypes {
  organizationRelationshipTypes {
    id
    name
    description
  }
}
    `;

/**
 * __useGetRelationshipTypesQuery__
 *
 * To run a query within a React component, call `useGetRelationshipTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelationshipTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelationshipTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRelationshipTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetRelationshipTypesQuery, GetRelationshipTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRelationshipTypesQuery, GetRelationshipTypesQueryVariables>(GetRelationshipTypesDocument, options);
      }
export function useGetRelationshipTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelationshipTypesQuery, GetRelationshipTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRelationshipTypesQuery, GetRelationshipTypesQueryVariables>(GetRelationshipTypesDocument, options);
        }
export function useGetRelationshipTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRelationshipTypesQuery, GetRelationshipTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRelationshipTypesQuery, GetRelationshipTypesQueryVariables>(GetRelationshipTypesDocument, options);
        }
export type GetRelationshipTypesQueryHookResult = ReturnType<typeof useGetRelationshipTypesQuery>;
export type GetRelationshipTypesLazyQueryHookResult = ReturnType<typeof useGetRelationshipTypesLazyQuery>;
export type GetRelationshipTypesSuspenseQueryHookResult = ReturnType<typeof useGetRelationshipTypesSuspenseQuery>;
export type GetRelationshipTypesQueryResult = Apollo.QueryResult<GetRelationshipTypesQuery, GetRelationshipTypesQueryVariables>;
export const GetUsersByOrganizationIdDocument = gql`
    query GetUsersByOrganizationId($organizationId: ID!, $limit: Int!, $offset: String) {
  usersByOrganizationId(organizationId: $organizationId, limit: $limit, offset: $offset) {
    nodes {
      id
      firstName
      lastName
      email
      enabled
      organizations {
        organizationId: id
        name
        roleId
        isBaseOrganization
        locations {
          id
          locationName
        }
      }
    }
    pageInfo {
      endCursor
      totalCount
    }
  }
}
    `;

/**
 * __useGetUsersByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUsersByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables> & ({ variables: GetUsersByOrganizationIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>(GetUsersByOrganizationIdDocument, options);
      }
export function useGetUsersByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>(GetUsersByOrganizationIdDocument, options);
        }
export function useGetUsersByOrganizationIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>(GetUsersByOrganizationIdDocument, options);
        }
export type GetUsersByOrganizationIdQueryHookResult = ReturnType<typeof useGetUsersByOrganizationIdQuery>;
export type GetUsersByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationIdLazyQuery>;
export type GetUsersByOrganizationIdSuspenseQueryHookResult = ReturnType<typeof useGetUsersByOrganizationIdSuspenseQuery>;
export type GetUsersByOrganizationIdQueryResult = Apollo.QueryResult<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>;
export const RemoveOrganizationRelationshipTypeAssociationDocument = gql`
    mutation RemoveOrganizationRelationshipTypeAssociation($input: RemoveOrganizationRelationshipTypeAssociationInput!) {
  removeOrganizationRelationshipTypeAssociation(input: $input) {
    removed
    errors {
      ... on UserInputError {
        message
      }
    }
  }
}
    `;
export type RemoveOrganizationRelationshipTypeAssociationMutationFn = Apollo.MutationFunction<RemoveOrganizationRelationshipTypeAssociationMutation, RemoveOrganizationRelationshipTypeAssociationMutationVariables>;

/**
 * __useRemoveOrganizationRelationshipTypeAssociationMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationRelationshipTypeAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationRelationshipTypeAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationRelationshipTypeAssociationMutation, { data, loading, error }] = useRemoveOrganizationRelationshipTypeAssociationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrganizationRelationshipTypeAssociationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganizationRelationshipTypeAssociationMutation, RemoveOrganizationRelationshipTypeAssociationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganizationRelationshipTypeAssociationMutation, RemoveOrganizationRelationshipTypeAssociationMutationVariables>(RemoveOrganizationRelationshipTypeAssociationDocument, options);
      }
export type RemoveOrganizationRelationshipTypeAssociationMutationHookResult = ReturnType<typeof useRemoveOrganizationRelationshipTypeAssociationMutation>;
export type RemoveOrganizationRelationshipTypeAssociationMutationResult = Apollo.MutationResult<RemoveOrganizationRelationshipTypeAssociationMutation>;
export type RemoveOrganizationRelationshipTypeAssociationMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizationRelationshipTypeAssociationMutation, RemoveOrganizationRelationshipTypeAssociationMutationVariables>;
export const AddOrganizationReleaseToggleDocument = gql`
    mutation addOrganizationReleaseToggle($input: AddOrganizationReleaseToggleInput!) {
  addOrganizationReleaseToggle(input: $input) {
    organization {
      id
    }
  }
}
    `;
export type AddOrganizationReleaseToggleMutationFn = Apollo.MutationFunction<AddOrganizationReleaseToggleMutation, AddOrganizationReleaseToggleMutationVariables>;

/**
 * __useAddOrganizationReleaseToggleMutation__
 *
 * To run a mutation, you first call `useAddOrganizationReleaseToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationReleaseToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationReleaseToggleMutation, { data, loading, error }] = useAddOrganizationReleaseToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationReleaseToggleMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationReleaseToggleMutation, AddOrganizationReleaseToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationReleaseToggleMutation, AddOrganizationReleaseToggleMutationVariables>(AddOrganizationReleaseToggleDocument, options);
      }
export type AddOrganizationReleaseToggleMutationHookResult = ReturnType<typeof useAddOrganizationReleaseToggleMutation>;
export type AddOrganizationReleaseToggleMutationResult = Apollo.MutationResult<AddOrganizationReleaseToggleMutation>;
export type AddOrganizationReleaseToggleMutationOptions = Apollo.BaseMutationOptions<AddOrganizationReleaseToggleMutation, AddOrganizationReleaseToggleMutationVariables>;
export const DeleteLogoDocument = gql`
    mutation deleteLogo($id: ID!) {
  deleteLogo(id: $id) {
    id
    logoLink
  }
}
    `;
export type DeleteLogoMutationFn = Apollo.MutationFunction<DeleteLogoMutation, DeleteLogoMutationVariables>;

/**
 * __useDeleteLogoMutation__
 *
 * To run a mutation, you first call `useDeleteLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogoMutation, { data, loading, error }] = useDeleteLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLogoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLogoMutation, DeleteLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLogoMutation, DeleteLogoMutationVariables>(DeleteLogoDocument, options);
      }
export type DeleteLogoMutationHookResult = ReturnType<typeof useDeleteLogoMutation>;
export type DeleteLogoMutationResult = Apollo.MutationResult<DeleteLogoMutation>;
export type DeleteLogoMutationOptions = Apollo.BaseMutationOptions<DeleteLogoMutation, DeleteLogoMutationVariables>;
export const DeleteOrganizationReleaseToggleDocument = gql`
    mutation deleteOrganizationReleaseToggle($input: DeleteOrganizationReleaseToggleInput!) {
  deleteOrganizationReleaseToggle(input: $input) {
    organization {
      id
    }
  }
}
    `;
export type DeleteOrganizationReleaseToggleMutationFn = Apollo.MutationFunction<DeleteOrganizationReleaseToggleMutation, DeleteOrganizationReleaseToggleMutationVariables>;

/**
 * __useDeleteOrganizationReleaseToggleMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationReleaseToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationReleaseToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationReleaseToggleMutation, { data, loading, error }] = useDeleteOrganizationReleaseToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationReleaseToggleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationReleaseToggleMutation, DeleteOrganizationReleaseToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationReleaseToggleMutation, DeleteOrganizationReleaseToggleMutationVariables>(DeleteOrganizationReleaseToggleDocument, options);
      }
export type DeleteOrganizationReleaseToggleMutationHookResult = ReturnType<typeof useDeleteOrganizationReleaseToggleMutation>;
export type DeleteOrganizationReleaseToggleMutationResult = Apollo.MutationResult<DeleteOrganizationReleaseToggleMutation>;
export type DeleteOrganizationReleaseToggleMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationReleaseToggleMutation, DeleteOrganizationReleaseToggleMutationVariables>;
export const DisableAuth0ForOrgDocument = gql`
    mutation DisableAuth0ForOrg($id: ID!) {
  disableAuth0ForOrganization(id: $id) {
    active
  }
}
    `;
export type DisableAuth0ForOrgMutationFn = Apollo.MutationFunction<DisableAuth0ForOrgMutation, DisableAuth0ForOrgMutationVariables>;

/**
 * __useDisableAuth0ForOrgMutation__
 *
 * To run a mutation, you first call `useDisableAuth0ForOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAuth0ForOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAuth0ForOrgMutation, { data, loading, error }] = useDisableAuth0ForOrgMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableAuth0ForOrgMutation(baseOptions?: Apollo.MutationHookOptions<DisableAuth0ForOrgMutation, DisableAuth0ForOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAuth0ForOrgMutation, DisableAuth0ForOrgMutationVariables>(DisableAuth0ForOrgDocument, options);
      }
export type DisableAuth0ForOrgMutationHookResult = ReturnType<typeof useDisableAuth0ForOrgMutation>;
export type DisableAuth0ForOrgMutationResult = Apollo.MutationResult<DisableAuth0ForOrgMutation>;
export type DisableAuth0ForOrgMutationOptions = Apollo.BaseMutationOptions<DisableAuth0ForOrgMutation, DisableAuth0ForOrgMutationVariables>;
export const EnableAuth0ForOrgDocument = gql`
    mutation EnableAuth0ForOrg($id: ID!) {
  enableAuth0ForOrganization(id: $id) {
    active
  }
}
    `;
export type EnableAuth0ForOrgMutationFn = Apollo.MutationFunction<EnableAuth0ForOrgMutation, EnableAuth0ForOrgMutationVariables>;

/**
 * __useEnableAuth0ForOrgMutation__
 *
 * To run a mutation, you first call `useEnableAuth0ForOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAuth0ForOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAuth0ForOrgMutation, { data, loading, error }] = useEnableAuth0ForOrgMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableAuth0ForOrgMutation(baseOptions?: Apollo.MutationHookOptions<EnableAuth0ForOrgMutation, EnableAuth0ForOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableAuth0ForOrgMutation, EnableAuth0ForOrgMutationVariables>(EnableAuth0ForOrgDocument, options);
      }
export type EnableAuth0ForOrgMutationHookResult = ReturnType<typeof useEnableAuth0ForOrgMutation>;
export type EnableAuth0ForOrgMutationResult = Apollo.MutationResult<EnableAuth0ForOrgMutation>;
export type EnableAuth0ForOrgMutationOptions = Apollo.BaseMutationOptions<EnableAuth0ForOrgMutation, EnableAuth0ForOrgMutationVariables>;
export const GetOrganizationDocument = gql`
    query getOrganization($id: ID!) {
  organization(id: $id) {
    id
    name
    slug
    mfa {
      enabled
      rememberDevice
    }
    locations {
      id
      locationSlug
      locationName
    }
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables> & ({ variables: GetOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export function useGetOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationSuspenseQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetOrganizationByIdDocument = gql`
    query getOrganizationById($id: ID!) {
  organization(id: $id) {
    id
    name
    slug
    sfId
    features
    releaseToggles
    mfa {
      enabled
      rememberDevice
    }
  }
}
    `;

/**
 * __useGetOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables> & ({ variables: GetOrganizationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
      }
export function useGetOrganizationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
        }
export function useGetOrganizationByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
        }
export type GetOrganizationByIdQueryHookResult = ReturnType<typeof useGetOrganizationByIdQuery>;
export type GetOrganizationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganizationByIdLazyQuery>;
export type GetOrganizationByIdSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationByIdSuspenseQuery>;
export type GetOrganizationByIdQueryResult = Apollo.QueryResult<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>;
export const GetOrganizationLocationsDocument = gql`
    query getOrganizationLocations($id: ID!, $limit: Int, $offset: Int) {
  organization(id: $id) {
    id
    locations(limit: $limit, offset: $offset) {
      id
      locationName
      locationSlug
    }
  }
}
    `;

/**
 * __useGetOrganizationLocationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrganizationLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables> & ({ variables: GetOrganizationLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
      }
export function useGetOrganizationLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
        }
export function useGetOrganizationLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
        }
export type GetOrganizationLocationsQueryHookResult = ReturnType<typeof useGetOrganizationLocationsQuery>;
export type GetOrganizationLocationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationLocationsLazyQuery>;
export type GetOrganizationLocationsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationLocationsSuspenseQuery>;
export type GetOrganizationLocationsQueryResult = Apollo.QueryResult<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>;
export const GetOrganizationLogoDocument = gql`
    query getOrganizationLogo($id: ID!) {
  organization(id: $id) {
    id
    logoLink
  }
}
    `;

/**
 * __useGetOrganizationLogoQuery__
 *
 * To run a query within a React component, call `useGetOrganizationLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationLogoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationLogoQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables> & ({ variables: GetOrganizationLogoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>(GetOrganizationLogoDocument, options);
      }
export function useGetOrganizationLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>(GetOrganizationLogoDocument, options);
        }
export function useGetOrganizationLogoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>(GetOrganizationLogoDocument, options);
        }
export type GetOrganizationLogoQueryHookResult = ReturnType<typeof useGetOrganizationLogoQuery>;
export type GetOrganizationLogoLazyQueryHookResult = ReturnType<typeof useGetOrganizationLogoLazyQuery>;
export type GetOrganizationLogoSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationLogoSuspenseQuery>;
export type GetOrganizationLogoQueryResult = Apollo.QueryResult<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>;
export const GetOrganizationsDocument = gql`
    query getOrganizations {
  organizations {
    id
    name
    slug
    sfId
    mfa {
      enabled
      rememberDevice
    }
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export function useGetOrganizationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationsSuspenseQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const SetFeaturesDocument = gql`
    mutation setFeatures($id: ID!, $features: [String!]!) {
  setFeatures(id: $id, features: $features)
}
    `;
export type SetFeaturesMutationFn = Apollo.MutationFunction<SetFeaturesMutation, SetFeaturesMutationVariables>;

/**
 * __useSetFeaturesMutation__
 *
 * To run a mutation, you first call `useSetFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeaturesMutation, { data, loading, error }] = useSetFeaturesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useSetFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<SetFeaturesMutation, SetFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFeaturesMutation, SetFeaturesMutationVariables>(SetFeaturesDocument, options);
      }
export type SetFeaturesMutationHookResult = ReturnType<typeof useSetFeaturesMutation>;
export type SetFeaturesMutationResult = Apollo.MutationResult<SetFeaturesMutation>;
export type SetFeaturesMutationOptions = Apollo.BaseMutationOptions<SetFeaturesMutation, SetFeaturesMutationVariables>;
export const SetOrganizationNameDocument = gql`
    mutation SetOrganizationName($input: SetOrganizationNameInput!) {
  setOrganizationName(input: $input) {
    organization {
      id
      name
    }
  }
}
    `;
export type SetOrganizationNameMutationFn = Apollo.MutationFunction<SetOrganizationNameMutation, SetOrganizationNameMutationVariables>;

/**
 * __useSetOrganizationNameMutation__
 *
 * To run a mutation, you first call `useSetOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationNameMutation, { data, loading, error }] = useSetOrganizationNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganizationNameMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganizationNameMutation, SetOrganizationNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganizationNameMutation, SetOrganizationNameMutationVariables>(SetOrganizationNameDocument, options);
      }
export type SetOrganizationNameMutationHookResult = ReturnType<typeof useSetOrganizationNameMutation>;
export type SetOrganizationNameMutationResult = Apollo.MutationResult<SetOrganizationNameMutation>;
export type SetOrganizationNameMutationOptions = Apollo.BaseMutationOptions<SetOrganizationNameMutation, SetOrganizationNameMutationVariables>;
export const SetOrganizationSfIdDocument = gql`
    mutation SetOrganizationSfId($input: SetOrganizationSfIdInput!) {
  setOrganizationSfId(input: $input) {
    organization {
      id
      name
      sfId
    }
  }
}
    `;
export type SetOrganizationSfIdMutationFn = Apollo.MutationFunction<SetOrganizationSfIdMutation, SetOrganizationSfIdMutationVariables>;

/**
 * __useSetOrganizationSfIdMutation__
 *
 * To run a mutation, you first call `useSetOrganizationSfIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationSfIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationSfIdMutation, { data, loading, error }] = useSetOrganizationSfIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganizationSfIdMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganizationSfIdMutation, SetOrganizationSfIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganizationSfIdMutation, SetOrganizationSfIdMutationVariables>(SetOrganizationSfIdDocument, options);
      }
export type SetOrganizationSfIdMutationHookResult = ReturnType<typeof useSetOrganizationSfIdMutation>;
export type SetOrganizationSfIdMutationResult = Apollo.MutationResult<SetOrganizationSfIdMutation>;
export type SetOrganizationSfIdMutationOptions = Apollo.BaseMutationOptions<SetOrganizationSfIdMutation, SetOrganizationSfIdMutationVariables>;
export const UpdateMfaForOrganizationDocument = gql`
    mutation UpdateMFAForOrganization($input: UpdateMFAForOrganizationInput!) {
  updateMFAForOrganization(input: $input) {
    organization {
      id
      mfa {
        enabled
        rememberDevice
      }
    }
  }
}
    `;
export type UpdateMfaForOrganizationMutationFn = Apollo.MutationFunction<UpdateMfaForOrganizationMutation, UpdateMfaForOrganizationMutationVariables>;

/**
 * __useUpdateMfaForOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateMfaForOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMfaForOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMfaForOrganizationMutation, { data, loading, error }] = useUpdateMfaForOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMfaForOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMfaForOrganizationMutation, UpdateMfaForOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMfaForOrganizationMutation, UpdateMfaForOrganizationMutationVariables>(UpdateMfaForOrganizationDocument, options);
      }
export type UpdateMfaForOrganizationMutationHookResult = ReturnType<typeof useUpdateMfaForOrganizationMutation>;
export type UpdateMfaForOrganizationMutationResult = Apollo.MutationResult<UpdateMfaForOrganizationMutation>;
export type UpdateMfaForOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateMfaForOrganizationMutation, UpdateMfaForOrganizationMutationVariables>;
export const UploadLogoDocument = gql`
    mutation uploadLogo($id: ID!, $file: Upload!) {
  uploadLogo(id: $id, image: $file) {
    id
    logoLink
  }
}
    `;
export type UploadLogoMutationFn = Apollo.MutationFunction<UploadLogoMutation, UploadLogoMutationVariables>;

/**
 * __useUploadLogoMutation__
 *
 * To run a mutation, you first call `useUploadLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLogoMutation, { data, loading, error }] = useUploadLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadLogoMutation, UploadLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadLogoMutation, UploadLogoMutationVariables>(UploadLogoDocument, options);
      }
export type UploadLogoMutationHookResult = ReturnType<typeof useUploadLogoMutation>;
export type UploadLogoMutationResult = Apollo.MutationResult<UploadLogoMutation>;
export type UploadLogoMutationOptions = Apollo.BaseMutationOptions<UploadLogoMutation, UploadLogoMutationVariables>;
export const AddGlobalReleaseToggleDocument = gql`
    mutation AddGlobalReleaseToggle($input: AddGlobalReleaseToggleInput!) {
  addGlobalReleaseToggle(input: $input) {
    releaseToggle {
      ...ReleaseToggleFields
    }
  }
}
    ${ReleaseToggleFieldsFragmentDoc}`;
export type AddGlobalReleaseToggleMutationFn = Apollo.MutationFunction<AddGlobalReleaseToggleMutation, AddGlobalReleaseToggleMutationVariables>;

/**
 * __useAddGlobalReleaseToggleMutation__
 *
 * To run a mutation, you first call `useAddGlobalReleaseToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalReleaseToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalReleaseToggleMutation, { data, loading, error }] = useAddGlobalReleaseToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGlobalReleaseToggleMutation(baseOptions?: Apollo.MutationHookOptions<AddGlobalReleaseToggleMutation, AddGlobalReleaseToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGlobalReleaseToggleMutation, AddGlobalReleaseToggleMutationVariables>(AddGlobalReleaseToggleDocument, options);
      }
export type AddGlobalReleaseToggleMutationHookResult = ReturnType<typeof useAddGlobalReleaseToggleMutation>;
export type AddGlobalReleaseToggleMutationResult = Apollo.MutationResult<AddGlobalReleaseToggleMutation>;
export type AddGlobalReleaseToggleMutationOptions = Apollo.BaseMutationOptions<AddGlobalReleaseToggleMutation, AddGlobalReleaseToggleMutationVariables>;
export const DeleteGlobalReleaseToggleDocument = gql`
    mutation DeleteGlobalReleaseToggle($input: DeleteGlobalReleaseToggleInput!) {
  deleteGlobalReleaseToggle(input: $input) {
    success
  }
}
    `;
export type DeleteGlobalReleaseToggleMutationFn = Apollo.MutationFunction<DeleteGlobalReleaseToggleMutation, DeleteGlobalReleaseToggleMutationVariables>;

/**
 * __useDeleteGlobalReleaseToggleMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalReleaseToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalReleaseToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalReleaseToggleMutation, { data, loading, error }] = useDeleteGlobalReleaseToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGlobalReleaseToggleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGlobalReleaseToggleMutation, DeleteGlobalReleaseToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGlobalReleaseToggleMutation, DeleteGlobalReleaseToggleMutationVariables>(DeleteGlobalReleaseToggleDocument, options);
      }
export type DeleteGlobalReleaseToggleMutationHookResult = ReturnType<typeof useDeleteGlobalReleaseToggleMutation>;
export type DeleteGlobalReleaseToggleMutationResult = Apollo.MutationResult<DeleteGlobalReleaseToggleMutation>;
export type DeleteGlobalReleaseToggleMutationOptions = Apollo.BaseMutationOptions<DeleteGlobalReleaseToggleMutation, DeleteGlobalReleaseToggleMutationVariables>;
export const GetGlobalReleaseTogglesDocument = gql`
    query GetGlobalReleaseToggles($names: [String!]) {
  globalReleaseToggles(names: $names) {
    ...ReleaseToggleFields
  }
}
    ${ReleaseToggleFieldsFragmentDoc}`;

/**
 * __useGetGlobalReleaseTogglesQuery__
 *
 * To run a query within a React component, call `useGetGlobalReleaseTogglesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalReleaseTogglesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalReleaseTogglesQuery({
 *   variables: {
 *      names: // value for 'names'
 *   },
 * });
 */
export function useGetGlobalReleaseTogglesQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalReleaseTogglesQuery, GetGlobalReleaseTogglesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalReleaseTogglesQuery, GetGlobalReleaseTogglesQueryVariables>(GetGlobalReleaseTogglesDocument, options);
      }
export function useGetGlobalReleaseTogglesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalReleaseTogglesQuery, GetGlobalReleaseTogglesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalReleaseTogglesQuery, GetGlobalReleaseTogglesQueryVariables>(GetGlobalReleaseTogglesDocument, options);
        }
export function useGetGlobalReleaseTogglesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGlobalReleaseTogglesQuery, GetGlobalReleaseTogglesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGlobalReleaseTogglesQuery, GetGlobalReleaseTogglesQueryVariables>(GetGlobalReleaseTogglesDocument, options);
        }
export type GetGlobalReleaseTogglesQueryHookResult = ReturnType<typeof useGetGlobalReleaseTogglesQuery>;
export type GetGlobalReleaseTogglesLazyQueryHookResult = ReturnType<typeof useGetGlobalReleaseTogglesLazyQuery>;
export type GetGlobalReleaseTogglesSuspenseQueryHookResult = ReturnType<typeof useGetGlobalReleaseTogglesSuspenseQuery>;
export type GetGlobalReleaseTogglesQueryResult = Apollo.QueryResult<GetGlobalReleaseTogglesQuery, GetGlobalReleaseTogglesQueryVariables>;
export const ToggleGlobalReleaseToggleDocument = gql`
    mutation ToggleGlobalReleaseToggle($input: ToggleGlobalReleaseToggleInput!) {
  toggleGlobalReleaseToggle(input: $input) {
    releaseToggle {
      ...ReleaseToggleFields
    }
  }
}
    ${ReleaseToggleFieldsFragmentDoc}`;
export type ToggleGlobalReleaseToggleMutationFn = Apollo.MutationFunction<ToggleGlobalReleaseToggleMutation, ToggleGlobalReleaseToggleMutationVariables>;

/**
 * __useToggleGlobalReleaseToggleMutation__
 *
 * To run a mutation, you first call `useToggleGlobalReleaseToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleGlobalReleaseToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleGlobalReleaseToggleMutation, { data, loading, error }] = useToggleGlobalReleaseToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleGlobalReleaseToggleMutation(baseOptions?: Apollo.MutationHookOptions<ToggleGlobalReleaseToggleMutation, ToggleGlobalReleaseToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleGlobalReleaseToggleMutation, ToggleGlobalReleaseToggleMutationVariables>(ToggleGlobalReleaseToggleDocument, options);
      }
export type ToggleGlobalReleaseToggleMutationHookResult = ReturnType<typeof useToggleGlobalReleaseToggleMutation>;
export type ToggleGlobalReleaseToggleMutationResult = Apollo.MutationResult<ToggleGlobalReleaseToggleMutation>;
export type ToggleGlobalReleaseToggleMutationOptions = Apollo.BaseMutationOptions<ToggleGlobalReleaseToggleMutation, ToggleGlobalReleaseToggleMutationVariables>;
export const AddOrganizationPermissionDocument = gql`
    mutation addOrganizationPermission($input: AddOrganizationPermissionInput!) {
  addOrganizationPermission(input: $input) {
    id
  }
}
    `;
export type AddOrganizationPermissionMutationFn = Apollo.MutationFunction<AddOrganizationPermissionMutation, AddOrganizationPermissionMutationVariables>;

/**
 * __useAddOrganizationPermissionMutation__
 *
 * To run a mutation, you first call `useAddOrganizationPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationPermissionMutation, { data, loading, error }] = useAddOrganizationPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationPermissionMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationPermissionMutation, AddOrganizationPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationPermissionMutation, AddOrganizationPermissionMutationVariables>(AddOrganizationPermissionDocument, options);
      }
export type AddOrganizationPermissionMutationHookResult = ReturnType<typeof useAddOrganizationPermissionMutation>;
export type AddOrganizationPermissionMutationResult = Apollo.MutationResult<AddOrganizationPermissionMutation>;
export type AddOrganizationPermissionMutationOptions = Apollo.BaseMutationOptions<AddOrganizationPermissionMutation, AddOrganizationPermissionMutationVariables>;
export const AddUserReleaseToggleDocument = gql`
    mutation addUserReleaseToggle($input: AddUserReleaseToggleInput!) {
  addUserReleaseToggle(input: $input) {
    user {
      id
    }
  }
}
    `;
export type AddUserReleaseToggleMutationFn = Apollo.MutationFunction<AddUserReleaseToggleMutation, AddUserReleaseToggleMutationVariables>;

/**
 * __useAddUserReleaseToggleMutation__
 *
 * To run a mutation, you first call `useAddUserReleaseToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserReleaseToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserReleaseToggleMutation, { data, loading, error }] = useAddUserReleaseToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserReleaseToggleMutation(baseOptions?: Apollo.MutationHookOptions<AddUserReleaseToggleMutation, AddUserReleaseToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserReleaseToggleMutation, AddUserReleaseToggleMutationVariables>(AddUserReleaseToggleDocument, options);
      }
export type AddUserReleaseToggleMutationHookResult = ReturnType<typeof useAddUserReleaseToggleMutation>;
export type AddUserReleaseToggleMutationResult = Apollo.MutationResult<AddUserReleaseToggleMutation>;
export type AddUserReleaseToggleMutationOptions = Apollo.BaseMutationOptions<AddUserReleaseToggleMutation, AddUserReleaseToggleMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($user: CreateUserInput!) {
  createUser(input: $user) {
    id
    email
    status
    groups {
      name
    }
    enabled
    lastName
    firstName
    createdAt
    updatedAt
    temporaryPassword
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteUserReleaseToggleDocument = gql`
    mutation deleteUserReleaseToggle($input: DeleteUserReleaseToggleInput!) {
  deleteUserReleaseToggle(input: $input) {
    user {
      id
    }
  }
}
    `;
export type DeleteUserReleaseToggleMutationFn = Apollo.MutationFunction<DeleteUserReleaseToggleMutation, DeleteUserReleaseToggleMutationVariables>;

/**
 * __useDeleteUserReleaseToggleMutation__
 *
 * To run a mutation, you first call `useDeleteUserReleaseToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserReleaseToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserReleaseToggleMutation, { data, loading, error }] = useDeleteUserReleaseToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserReleaseToggleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserReleaseToggleMutation, DeleteUserReleaseToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserReleaseToggleMutation, DeleteUserReleaseToggleMutationVariables>(DeleteUserReleaseToggleDocument, options);
      }
export type DeleteUserReleaseToggleMutationHookResult = ReturnType<typeof useDeleteUserReleaseToggleMutation>;
export type DeleteUserReleaseToggleMutationResult = Apollo.MutationResult<DeleteUserReleaseToggleMutation>;
export type DeleteUserReleaseToggleMutationOptions = Apollo.BaseMutationOptions<DeleteUserReleaseToggleMutation, DeleteUserReleaseToggleMutationVariables>;
export const DisableUserDocument = gql`
    mutation disableUser($id: ID!) {
  disableUser(id: $id)
}
    `;
export type DisableUserMutationFn = Apollo.MutationFunction<DisableUserMutation, DisableUserMutationVariables>;

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableUserMutation(baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, options);
      }
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>;
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>;
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<DisableUserMutation, DisableUserMutationVariables>;
export const EnableUserDocument = gql`
    mutation enableUser($id: ID!) {
  enableUser(id: $id)
}
    `;
export type EnableUserMutationFn = Apollo.MutationFunction<EnableUserMutation, EnableUserMutationVariables>;

/**
 * __useEnableUserMutation__
 *
 * To run a mutation, you first call `useEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserMutation, { data, loading, error }] = useEnableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableUserMutation(baseOptions?: Apollo.MutationHookOptions<EnableUserMutation, EnableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument, options);
      }
export type EnableUserMutationHookResult = ReturnType<typeof useEnableUserMutation>;
export type EnableUserMutationResult = Apollo.MutationResult<EnableUserMutation>;
export type EnableUserMutationOptions = Apollo.BaseMutationOptions<EnableUserMutation, EnableUserMutationVariables>;
export const UserByEmailDocument = gql`
    query userByEmail($email: String!) {
  userByEmail(email: $email) {
    id
    email
    status
    enabled
    lastName
    firstName
    createdAt
    updatedAt
    features
    canUseDevTools
    auth0 {
      id
      migratedAt
      invitedAt
      mfaEnrollments {
        id
        status
        type
      }
    }
    groups {
      name
    }
    organizations {
      id
      name
      slug
      roleId
      niaaRoleId
      isBaseOrganization
      canAccessInProgress
      canReceiveCDN
      expires
      mfa {
        enabled
        rememberDevice
      }
      locations {
        id
        locationCity
        locationName
        locationSlug
        locationState
        locationZip
      }
    }
    releaseToggles
  }
}
    `;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables> & ({ variables: UserByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
      }
export function useUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
        }
export function useUserByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
        }
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>;
export type UserByEmailSuspenseQueryHookResult = ReturnType<typeof useUserByEmailSuspenseQuery>;
export type UserByEmailQueryResult = Apollo.QueryResult<UserByEmailQuery, UserByEmailQueryVariables>;
export const GetUserEmailsDocument = gql`
    query GetUserEmails($filter: String) {
  userEmails(filter: $filter)
}
    `;

/**
 * __useGetUserEmailsQuery__
 *
 * To run a query within a React component, call `useGetUserEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEmailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUserEmailsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEmailsQuery, GetUserEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEmailsQuery, GetUserEmailsQueryVariables>(GetUserEmailsDocument, options);
      }
export function useGetUserEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEmailsQuery, GetUserEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEmailsQuery, GetUserEmailsQueryVariables>(GetUserEmailsDocument, options);
        }
export function useGetUserEmailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserEmailsQuery, GetUserEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserEmailsQuery, GetUserEmailsQueryVariables>(GetUserEmailsDocument, options);
        }
export type GetUserEmailsQueryHookResult = ReturnType<typeof useGetUserEmailsQuery>;
export type GetUserEmailsLazyQueryHookResult = ReturnType<typeof useGetUserEmailsLazyQuery>;
export type GetUserEmailsSuspenseQueryHookResult = ReturnType<typeof useGetUserEmailsSuspenseQuery>;
export type GetUserEmailsQueryResult = Apollo.QueryResult<GetUserEmailsQuery, GetUserEmailsQueryVariables>;
export const GetUserGroupsDocument = gql`
    query getUserGroups {
  userGroups {
    name
  }
}
    `;

/**
 * __useGetUserGroupsQuery__
 *
 * To run a query within a React component, call `useGetUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserGroupsQuery, GetUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, options);
      }
export function useGetUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGroupsQuery, GetUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, options);
        }
export function useGetUserGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserGroupsQuery, GetUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, options);
        }
export type GetUserGroupsQueryHookResult = ReturnType<typeof useGetUserGroupsQuery>;
export type GetUserGroupsLazyQueryHookResult = ReturnType<typeof useGetUserGroupsLazyQuery>;
export type GetUserGroupsSuspenseQueryHookResult = ReturnType<typeof useGetUserGroupsSuspenseQuery>;
export type GetUserGroupsQueryResult = Apollo.QueryResult<GetUserGroupsQuery, GetUserGroupsQueryVariables>;
export const GetUserNiaaRolesDocument = gql`
    query getUserNiaaRoles {
  userNiaaRoles {
    name
    isDeprecated
  }
}
    `;

/**
 * __useGetUserNiaaRolesQuery__
 *
 * To run a query within a React component, call `useGetUserNiaaRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNiaaRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNiaaRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNiaaRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNiaaRolesQuery, GetUserNiaaRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNiaaRolesQuery, GetUserNiaaRolesQueryVariables>(GetUserNiaaRolesDocument, options);
      }
export function useGetUserNiaaRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNiaaRolesQuery, GetUserNiaaRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNiaaRolesQuery, GetUserNiaaRolesQueryVariables>(GetUserNiaaRolesDocument, options);
        }
export function useGetUserNiaaRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserNiaaRolesQuery, GetUserNiaaRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserNiaaRolesQuery, GetUserNiaaRolesQueryVariables>(GetUserNiaaRolesDocument, options);
        }
export type GetUserNiaaRolesQueryHookResult = ReturnType<typeof useGetUserNiaaRolesQuery>;
export type GetUserNiaaRolesLazyQueryHookResult = ReturnType<typeof useGetUserNiaaRolesLazyQuery>;
export type GetUserNiaaRolesSuspenseQueryHookResult = ReturnType<typeof useGetUserNiaaRolesSuspenseQuery>;
export type GetUserNiaaRolesQueryResult = Apollo.QueryResult<GetUserNiaaRolesQuery, GetUserNiaaRolesQueryVariables>;
export const GetUserRolesDocument = gql`
    query getUserRoles {
  userRoles {
    name
    isDeprecated
  }
}
    `;

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
      }
export function useGetUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
        }
export function useGetUserRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
        }
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>;
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>;
export type GetUserRolesSuspenseQueryHookResult = ReturnType<typeof useGetUserRolesSuspenseQuery>;
export type GetUserRolesQueryResult = Apollo.QueryResult<GetUserRolesQuery, GetUserRolesQueryVariables>;
export const RemoveOrganizationPermissionDocument = gql`
    mutation removeOrganizationPermission($input: RemoveOrganizationPermissionInput!) {
  removeOrganizationPermission(input: $input) {
    id
  }
}
    `;
export type RemoveOrganizationPermissionMutationFn = Apollo.MutationFunction<RemoveOrganizationPermissionMutation, RemoveOrganizationPermissionMutationVariables>;

/**
 * __useRemoveOrganizationPermissionMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationPermissionMutation, { data, loading, error }] = useRemoveOrganizationPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrganizationPermissionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganizationPermissionMutation, RemoveOrganizationPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganizationPermissionMutation, RemoveOrganizationPermissionMutationVariables>(RemoveOrganizationPermissionDocument, options);
      }
export type RemoveOrganizationPermissionMutationHookResult = ReturnType<typeof useRemoveOrganizationPermissionMutation>;
export type RemoveOrganizationPermissionMutationResult = Apollo.MutationResult<RemoveOrganizationPermissionMutation>;
export type RemoveOrganizationPermissionMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizationPermissionMutation, RemoveOrganizationPermissionMutationVariables>;
export const RemoveUserMfaEnrollmentDocument = gql`
    mutation removeUserMFAEnrollment($input: RemoveUserMFAEnrollmentInput!) {
  removeUserMFAEnrollment(input: $input) {
    user {
      id
    }
  }
}
    `;
export type RemoveUserMfaEnrollmentMutationFn = Apollo.MutationFunction<RemoveUserMfaEnrollmentMutation, RemoveUserMfaEnrollmentMutationVariables>;

/**
 * __useRemoveUserMfaEnrollmentMutation__
 *
 * To run a mutation, you first call `useRemoveUserMfaEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMfaEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMfaEnrollmentMutation, { data, loading, error }] = useRemoveUserMfaEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserMfaEnrollmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMfaEnrollmentMutation, RemoveUserMfaEnrollmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMfaEnrollmentMutation, RemoveUserMfaEnrollmentMutationVariables>(RemoveUserMfaEnrollmentDocument, options);
      }
export type RemoveUserMfaEnrollmentMutationHookResult = ReturnType<typeof useRemoveUserMfaEnrollmentMutation>;
export type RemoveUserMfaEnrollmentMutationResult = Apollo.MutationResult<RemoveUserMfaEnrollmentMutation>;
export type RemoveUserMfaEnrollmentMutationOptions = Apollo.BaseMutationOptions<RemoveUserMfaEnrollmentMutation, RemoveUserMfaEnrollmentMutationVariables>;
export const SetUserBaseOrgDocument = gql`
    mutation setUserBaseOrg($id: ID!, $organizationId: ID!) {
  setUserBaseOrg(id: $id, organizationId: $organizationId) {
    id
  }
}
    `;
export type SetUserBaseOrgMutationFn = Apollo.MutationFunction<SetUserBaseOrgMutation, SetUserBaseOrgMutationVariables>;

/**
 * __useSetUserBaseOrgMutation__
 *
 * To run a mutation, you first call `useSetUserBaseOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserBaseOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserBaseOrgMutation, { data, loading, error }] = useSetUserBaseOrgMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSetUserBaseOrgMutation(baseOptions?: Apollo.MutationHookOptions<SetUserBaseOrgMutation, SetUserBaseOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserBaseOrgMutation, SetUserBaseOrgMutationVariables>(SetUserBaseOrgDocument, options);
      }
export type SetUserBaseOrgMutationHookResult = ReturnType<typeof useSetUserBaseOrgMutation>;
export type SetUserBaseOrgMutationResult = Apollo.MutationResult<SetUserBaseOrgMutation>;
export type SetUserBaseOrgMutationOptions = Apollo.BaseMutationOptions<SetUserBaseOrgMutation, SetUserBaseOrgMutationVariables>;
export const SetCanAccessInProgressDocument = gql`
    mutation setCanAccessInProgress($input: CanAccessInProgressInput!) {
  setCanAccessInProgress(input: $input) {
    id
  }
}
    `;
export type SetCanAccessInProgressMutationFn = Apollo.MutationFunction<SetCanAccessInProgressMutation, SetCanAccessInProgressMutationVariables>;

/**
 * __useSetCanAccessInProgressMutation__
 *
 * To run a mutation, you first call `useSetCanAccessInProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCanAccessInProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCanAccessInProgressMutation, { data, loading, error }] = useSetCanAccessInProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCanAccessInProgressMutation(baseOptions?: Apollo.MutationHookOptions<SetCanAccessInProgressMutation, SetCanAccessInProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCanAccessInProgressMutation, SetCanAccessInProgressMutationVariables>(SetCanAccessInProgressDocument, options);
      }
export type SetCanAccessInProgressMutationHookResult = ReturnType<typeof useSetCanAccessInProgressMutation>;
export type SetCanAccessInProgressMutationResult = Apollo.MutationResult<SetCanAccessInProgressMutation>;
export type SetCanAccessInProgressMutationOptions = Apollo.BaseMutationOptions<SetCanAccessInProgressMutation, SetCanAccessInProgressMutationVariables>;
export const UpdateCanReceiveCdnDocument = gql`
    mutation UpdateCanReceiveCDN($input: CanReceiveCDNInput!) {
  setCanReceiveCDN(input: $input) {
    id
  }
}
    `;
export type UpdateCanReceiveCdnMutationFn = Apollo.MutationFunction<UpdateCanReceiveCdnMutation, UpdateCanReceiveCdnMutationVariables>;

/**
 * __useUpdateCanReceiveCdnMutation__
 *
 * To run a mutation, you first call `useUpdateCanReceiveCdnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCanReceiveCdnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCanReceiveCdnMutation, { data, loading, error }] = useUpdateCanReceiveCdnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCanReceiveCdnMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCanReceiveCdnMutation, UpdateCanReceiveCdnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCanReceiveCdnMutation, UpdateCanReceiveCdnMutationVariables>(UpdateCanReceiveCdnDocument, options);
      }
export type UpdateCanReceiveCdnMutationHookResult = ReturnType<typeof useUpdateCanReceiveCdnMutation>;
export type UpdateCanReceiveCdnMutationResult = Apollo.MutationResult<UpdateCanReceiveCdnMutation>;
export type UpdateCanReceiveCdnMutationOptions = Apollo.BaseMutationOptions<UpdateCanReceiveCdnMutation, UpdateCanReceiveCdnMutationVariables>;
export const SetCanUseDevToolsDocument = gql`
    mutation setCanUseDevTools($input: SetUserCanUseDevToolsInput!) {
  setUserCanUseDevTools(input: $input) {
    user {
      id
    }
  }
}
    `;
export type SetCanUseDevToolsMutationFn = Apollo.MutationFunction<SetCanUseDevToolsMutation, SetCanUseDevToolsMutationVariables>;

/**
 * __useSetCanUseDevToolsMutation__
 *
 * To run a mutation, you first call `useSetCanUseDevToolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCanUseDevToolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCanUseDevToolsMutation, { data, loading, error }] = useSetCanUseDevToolsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCanUseDevToolsMutation(baseOptions?: Apollo.MutationHookOptions<SetCanUseDevToolsMutation, SetCanUseDevToolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCanUseDevToolsMutation, SetCanUseDevToolsMutationVariables>(SetCanUseDevToolsDocument, options);
      }
export type SetCanUseDevToolsMutationHookResult = ReturnType<typeof useSetCanUseDevToolsMutation>;
export type SetCanUseDevToolsMutationResult = Apollo.MutationResult<SetCanUseDevToolsMutation>;
export type SetCanUseDevToolsMutationOptions = Apollo.BaseMutationOptions<SetCanUseDevToolsMutation, SetCanUseDevToolsMutationVariables>;
export const SetUserEmailDocument = gql`
    mutation setUserEmail($input: SetUserEmailInput!) {
  setUserEmail(input: $input) {
    id
  }
}
    `;
export type SetUserEmailMutationFn = Apollo.MutationFunction<SetUserEmailMutation, SetUserEmailMutationVariables>;

/**
 * __useSetUserEmailMutation__
 *
 * To run a mutation, you first call `useSetUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserEmailMutation, { data, loading, error }] = useSetUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<SetUserEmailMutation, SetUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserEmailMutation, SetUserEmailMutationVariables>(SetUserEmailDocument, options);
      }
export type SetUserEmailMutationHookResult = ReturnType<typeof useSetUserEmailMutation>;
export type SetUserEmailMutationResult = Apollo.MutationResult<SetUserEmailMutation>;
export type SetUserEmailMutationOptions = Apollo.BaseMutationOptions<SetUserEmailMutation, SetUserEmailMutationVariables>;
export const SetUserFeaturesDocument = gql`
    mutation setUserFeatures($id: ID!, $features: [String!]!) {
  setUserFeatures(id: $id, features: $features)
}
    `;
export type SetUserFeaturesMutationFn = Apollo.MutationFunction<SetUserFeaturesMutation, SetUserFeaturesMutationVariables>;

/**
 * __useSetUserFeaturesMutation__
 *
 * To run a mutation, you first call `useSetUserFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserFeaturesMutation, { data, loading, error }] = useSetUserFeaturesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useSetUserFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<SetUserFeaturesMutation, SetUserFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserFeaturesMutation, SetUserFeaturesMutationVariables>(SetUserFeaturesDocument, options);
      }
export type SetUserFeaturesMutationHookResult = ReturnType<typeof useSetUserFeaturesMutation>;
export type SetUserFeaturesMutationResult = Apollo.MutationResult<SetUserFeaturesMutation>;
export type SetUserFeaturesMutationOptions = Apollo.BaseMutationOptions<SetUserFeaturesMutation, SetUserFeaturesMutationVariables>;
export const SetUserFirstLastNameDocument = gql`
    mutation setUserFirstLastName($input: SetUserFirstLastNameInput!) {
  setUserFirstLastName(input: $input) {
    id
  }
}
    `;
export type SetUserFirstLastNameMutationFn = Apollo.MutationFunction<SetUserFirstLastNameMutation, SetUserFirstLastNameMutationVariables>;

/**
 * __useSetUserFirstLastNameMutation__
 *
 * To run a mutation, you first call `useSetUserFirstLastNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserFirstLastNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserFirstLastNameMutation, { data, loading, error }] = useSetUserFirstLastNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserFirstLastNameMutation(baseOptions?: Apollo.MutationHookOptions<SetUserFirstLastNameMutation, SetUserFirstLastNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserFirstLastNameMutation, SetUserFirstLastNameMutationVariables>(SetUserFirstLastNameDocument, options);
      }
export type SetUserFirstLastNameMutationHookResult = ReturnType<typeof useSetUserFirstLastNameMutation>;
export type SetUserFirstLastNameMutationResult = Apollo.MutationResult<SetUserFirstLastNameMutation>;
export type SetUserFirstLastNameMutationOptions = Apollo.BaseMutationOptions<SetUserFirstLastNameMutation, SetUserFirstLastNameMutationVariables>;
export const SetUserGroupsDocument = gql`
    mutation setUserGroups($input: SetUserGroupsInput!) {
  setUserGroups(input: $input) {
    groups {
      name
    }
  }
}
    `;
export type SetUserGroupsMutationFn = Apollo.MutationFunction<SetUserGroupsMutation, SetUserGroupsMutationVariables>;

/**
 * __useSetUserGroupsMutation__
 *
 * To run a mutation, you first call `useSetUserGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserGroupsMutation, { data, loading, error }] = useSetUserGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserGroupsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserGroupsMutation, SetUserGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserGroupsMutation, SetUserGroupsMutationVariables>(SetUserGroupsDocument, options);
      }
export type SetUserGroupsMutationHookResult = ReturnType<typeof useSetUserGroupsMutation>;
export type SetUserGroupsMutationResult = Apollo.MutationResult<SetUserGroupsMutation>;
export type SetUserGroupsMutationOptions = Apollo.BaseMutationOptions<SetUserGroupsMutation, SetUserGroupsMutationVariables>;
export const SetUserLocationsDocument = gql`
    mutation setUserLocations($input: SetUserLocationsInput!) {
  setUserLocations(input: $input) {
    id
  }
}
    `;
export type SetUserLocationsMutationFn = Apollo.MutationFunction<SetUserLocationsMutation, SetUserLocationsMutationVariables>;

/**
 * __useSetUserLocationsMutation__
 *
 * To run a mutation, you first call `useSetUserLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserLocationsMutation, { data, loading, error }] = useSetUserLocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserLocationsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserLocationsMutation, SetUserLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserLocationsMutation, SetUserLocationsMutationVariables>(SetUserLocationsDocument, options);
      }
export type SetUserLocationsMutationHookResult = ReturnType<typeof useSetUserLocationsMutation>;
export type SetUserLocationsMutationResult = Apollo.MutationResult<SetUserLocationsMutation>;
export type SetUserLocationsMutationOptions = Apollo.BaseMutationOptions<SetUserLocationsMutation, SetUserLocationsMutationVariables>;
export const SetUserOrgExpirationDocument = gql`
    mutation setUserOrgExpiration($input: SetUserOrgExpirationInput!) {
  setUserOrgExpiration(input: $input) {
    organizations {
      expires
    }
  }
}
    `;
export type SetUserOrgExpirationMutationFn = Apollo.MutationFunction<SetUserOrgExpirationMutation, SetUserOrgExpirationMutationVariables>;

/**
 * __useSetUserOrgExpirationMutation__
 *
 * To run a mutation, you first call `useSetUserOrgExpirationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserOrgExpirationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserOrgExpirationMutation, { data, loading, error }] = useSetUserOrgExpirationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserOrgExpirationMutation(baseOptions?: Apollo.MutationHookOptions<SetUserOrgExpirationMutation, SetUserOrgExpirationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserOrgExpirationMutation, SetUserOrgExpirationMutationVariables>(SetUserOrgExpirationDocument, options);
      }
export type SetUserOrgExpirationMutationHookResult = ReturnType<typeof useSetUserOrgExpirationMutation>;
export type SetUserOrgExpirationMutationResult = Apollo.MutationResult<SetUserOrgExpirationMutation>;
export type SetUserOrgExpirationMutationOptions = Apollo.BaseMutationOptions<SetUserOrgExpirationMutation, SetUserOrgExpirationMutationVariables>;
export const SetUserOrgNiaaRoleDocument = gql`
    mutation setUserOrgNiaaRole($input: SetUserOrgNiaaRoleInput!) {
  setUserOrgNiaaRole(input: $input) {
    user {
      organizations {
        niaaRoleId
      }
    }
  }
}
    `;
export type SetUserOrgNiaaRoleMutationFn = Apollo.MutationFunction<SetUserOrgNiaaRoleMutation, SetUserOrgNiaaRoleMutationVariables>;

/**
 * __useSetUserOrgNiaaRoleMutation__
 *
 * To run a mutation, you first call `useSetUserOrgNiaaRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserOrgNiaaRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserOrgNiaaRoleMutation, { data, loading, error }] = useSetUserOrgNiaaRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserOrgNiaaRoleMutation(baseOptions?: Apollo.MutationHookOptions<SetUserOrgNiaaRoleMutation, SetUserOrgNiaaRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserOrgNiaaRoleMutation, SetUserOrgNiaaRoleMutationVariables>(SetUserOrgNiaaRoleDocument, options);
      }
export type SetUserOrgNiaaRoleMutationHookResult = ReturnType<typeof useSetUserOrgNiaaRoleMutation>;
export type SetUserOrgNiaaRoleMutationResult = Apollo.MutationResult<SetUserOrgNiaaRoleMutation>;
export type SetUserOrgNiaaRoleMutationOptions = Apollo.BaseMutationOptions<SetUserOrgNiaaRoleMutation, SetUserOrgNiaaRoleMutationVariables>;
export const SetUserOrgRoleDocument = gql`
    mutation setUserOrgRole($input: SetUserOrgRoleInput!) {
  setUserOrgRole(input: $input) {
    organizations {
      roleId
    }
  }
}
    `;
export type SetUserOrgRoleMutationFn = Apollo.MutationFunction<SetUserOrgRoleMutation, SetUserOrgRoleMutationVariables>;

/**
 * __useSetUserOrgRoleMutation__
 *
 * To run a mutation, you first call `useSetUserOrgRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserOrgRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserOrgRoleMutation, { data, loading, error }] = useSetUserOrgRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserOrgRoleMutation(baseOptions?: Apollo.MutationHookOptions<SetUserOrgRoleMutation, SetUserOrgRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserOrgRoleMutation, SetUserOrgRoleMutationVariables>(SetUserOrgRoleDocument, options);
      }
export type SetUserOrgRoleMutationHookResult = ReturnType<typeof useSetUserOrgRoleMutation>;
export type SetUserOrgRoleMutationResult = Apollo.MutationResult<SetUserOrgRoleMutation>;
export type SetUserOrgRoleMutationOptions = Apollo.BaseMutationOptions<SetUserOrgRoleMutation, SetUserOrgRoleMutationVariables>;
export const GetAccountDocument = gql`
    query getAccount {
  account
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export function useGetAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountSuspenseQueryHookResult = ReturnType<typeof useGetAccountSuspenseQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;