import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { useRouteMatch, Link } from 'react-router-dom';

export default function NavMenu() {
  const rootMatch = useRouteMatch('/');
  const usersMatch = useRouteMatch('/users');
  const customersMatch = useRouteMatch('/organizations');
  const releaseManagement = useRouteMatch('/release-management');

  return (
    <Menu secondary pointing>
      <Menu.Item to="/users" as={Link} active={Boolean(usersMatch) || rootMatch?.isExact}>
        <Icon name="users" />
        Users
      </Menu.Item>
      <Menu.Item to="/organizations" as={Link} active={Boolean(customersMatch)}>
        <Icon name="building" />
        Organizations
      </Menu.Item>
      <Menu.Item to="/release-management" as={Link} active={Boolean(releaseManagement)}>
        <Icon name="rocket" />
        Release Management
      </Menu.Item>
    </Menu>
  );
}
