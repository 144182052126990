import React from 'react';
import styled from 'styled-components';
import { camelCase, startCase } from 'lodash';
import { List, Popup, Message, Button } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHighlight } from 'lib/styles';

const { Item, Icon, Content } = List;

const Name = styled.h2`
  margin: 0 0 0.5em 0;
`;

export default function UserMetadata({ user }) {
  const { firstName, lastName, id, enabled, status, email, createdAt, updatedAt, auth0 } = user;
  const { highlight, HighlightContainer, setRef } = useHighlight({
    containerStyles: `
      margin-left: -1px;
      padding: 2px 5px;
    `,
  });

  return (
    <div>
      {!auth0 && (
        <Message color="red">
          This user account was not migrated to Auth0 and will not function properly. Delete user
          and recreate to restore access.
        </Message>
      )}
      <Name>
        {firstName || '<Unknown>'} {lastName || '<Unknown>'} &nbsp;
        <Popup
          content={`This user is ${enabled ? 'enabled' : 'disabled'}`}
          trigger={<Icon name="check circle" color={enabled ? 'green' : 'grey'} />}
        />
      </Name>
      <List>
        <Item>
          <Icon name="id badge" />
          <Content>
            <HighlightContainer ref={setRef}>{id}</HighlightContainer>{' '}
            <CopyToClipboard onCopy={highlight} text={id}>
              <Icon name="copy" link />
            </CopyToClipboard>
          </Content>
        </Item>
        <Item>
          <Icon name="mail" />
          <Content>{email ? <a href={`mailto:${email}`}>{email}</a> : '<Unknown>'}</Content>
        </Item>
        <Item>
          <Icon name="setting" />
          <Content>Status: {startCase(camelCase(status))}</Content>
        </Item>
        <Item>
          <Icon name="time" />
          <Content>
            Created: {createdAt ? new Date(createdAt).toLocaleString() : '<unknown>'}
          </Content>
        </Item>
        <Item>
          <Icon name="time" />
          <Content>
            Modified: {updatedAt ? new Date(updatedAt).toLocaleString() : '<unknown>'}
          </Content>
        </Item>
        <Item>
          <Icon name="id badge" />
          <Content>Auth0 ID: {auth0?.id || 'Not an Auth0 User'}</Content>
        </Item>
        {auth0 && (
          <Item>
            <Icon name="time" />
            {auth0.invitedAt && (
              <Content>
                Invited to Auth0:{' '}
                {auth0.invitedAt ? new Date(auth0.invitedAt).toLocaleDateString() : 'NotSet'}
              </Content>
            )}
            {auth0.migratedAt && (
              <Content>
                Migrated to Auth0:{' '}
                {auth0.migratedAt ? new Date(auth0.migratedAt).toLocaleString() : 'Not set'}
              </Content>
            )}
            {!auth0.invitedAt && !auth0.migratedAt && <Content>Auth0: Dates not set</Content>}
          </Item>
        )}
      </List>
    </div>
  );
}
