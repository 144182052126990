import React from 'react';
import { Message, Loader, SemanticCOLORS } from 'semantic-ui-react';
import { useGetAccountQuery } from 'generated/graphql';

interface AccountMap {
  [key: string]: {
    name: string;
    color: SemanticCOLORS;
    text: string;
  };
}

const ACCOUNTS: AccountMap = {
  '084645560908': {
    name: 'development',
    color: 'green',
    text: 'Blow stuff up. 💣💣💣',
  },
  688987579355: {
    name: 'staging',
    color: 'yellow',
    text: 'Fire away. 🔥🔥🔥',
  },
  903032102471: {
    name: 'production',
    color: 'red',
    text: 'Be careful! ☠️',
  },
};

export default function EnvironmentMessage() {
  const { data, loading } = useGetAccountQuery();
  const account = data?.account;

  if (loading || !account) return <Loader active />;

  if (!(account in ACCOUNTS)) {
    return <Message color="red">🔥 Unknown account: {account ?? 'ID not found'}</Message>;
  }

  const { name, color, text } = ACCOUNTS[account];
  return (
    <Message color={color}>
      You are managing the {name} account. {text}
    </Message>
  );
}
