import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes, StyledComponent } from 'styled-components';

const highlightKeyframes = keyframes`
  from {
    background: transparent;
  }
  25% {
    background: rgba(33, 133, 208, .2);
  }
  100% {
    background: transparent;
  }
`;

export const highlightCss = css`
  &.highlight,
  .highlight {
    animation-name: ${highlightKeyframes};
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
`;

export const HighlightContainer = styled.span`
  ${highlightCss}
`;

let timeout: NodeJS.Timeout;

interface UseHighlightArgs {
  containerStyles?: string;
  duration?: number;
}

export function useHighlight<T extends HTMLElement>({
  containerStyles,
  // this default value aligns with the animation-duration for highlightCss
  duration = 1500,
}: UseHighlightArgs = {}) {
  const container = useRef<T | null>();
  const [Component, setComponent] = useState<StyledComponent<'span', any>>(HighlightContainer);

  const highlight = useCallback(() => {
    if (timeout) clearTimeout(timeout);
    container.current?.classList.add('highlight');

    timeout = setTimeout(() => {
      container.current?.classList.remove('highlight');
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  const setRef = useCallback((ref: T) => {
    container.current = ref;
  }, []);

  useEffect(() => {
    if (containerStyles) {
      setComponent(styled(HighlightContainer)`
        ${containerStyles}
      `);
    }
  }, [containerStyles]);

  return { highlight, HighlightContainer: Component, setRef };
}
