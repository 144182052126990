import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Loader, Container } from 'semantic-ui-react';
import AuthButtons from './AuthButtons';
import Footer, { FOOTER_HEIGHT } from './Footer';
import { useAuth0 } from '../lib/auth0';

const Content = styled.div`
  padding-top: 20px;
  min-height: calc(100vh - ${FOOTER_HEIGHT});
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { isLoading } = useAuth0();

  if (isLoading) return <Loader active />;

  return (
    <Container>
      <Content>
        <TitleContainer>
          <h1>SkySpecs Account Manager</h1>
          <AuthButtons />
        </TitleContainer>
        {children}
      </Content>
      <Footer />
    </Container>
  );
};

export default Layout;
