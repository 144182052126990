import { Any, List, Object } from 'ts-toolbelt';

export type NonNullPath<O extends any, P extends List.List<Any.Key>> = NonNullable<
  Object.Path<O, P>
>;

export const notEmpty = <TValue>(value: TValue | null | undefined | false): value is TValue => {
  return value !== null && value !== undefined;
};

export type SingularElement<T> = T extends (infer U)[]
  ? U
  : T extends null | undefined
    ? never
    : never;
