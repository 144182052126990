import React, { useState, FC, BaseSyntheticEvent } from 'react';
import { useMutation } from '@apollo/client';
import { Input, Button, Header, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import SET_SFID_MUTATION from './queries/setOrganizationSfId.gql';
import { GetOrganizationByIdQuery } from '../../generated/graphql';

const Container = styled.div`
  margin: 1.5em 0 1.5em 0;
`;

interface OrganizationSalesforceIDParams {
  organization: GetOrganizationByIdQuery['organization'];
}

const OrganizationSalesforceID: FC<OrganizationSalesforceIDParams> = ({ organization }) => {
  const { addToast } = useToasts();
  const [sfId, setSfId] = useState<string | null>(organization.sfId ?? null);
  const [submitSfIdChange, { loading }] = useMutation(SET_SFID_MUTATION, {
    onCompleted: data => {
      addToast(`SalesForce ID Updated to ${data.setOrganizationSfId.organization.sfId}`, {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: err => {
      addToast(`Error updating SalesForceID: ${err.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });

  const handleChange = (e: BaseSyntheticEvent) => setSfId(e.target.value);

  const handleSubmit = () => {
    submitSfIdChange({
      variables: {
        input: {
          id: organization.id,
          sfId,
        },
      },
    });
  };

  return (
    <Container>
      <Header as="h3">Salesforce ID</Header>
      <Grid>
        <Grid.Column width={12}>
          <Input
            fluid
            disabled={loading}
            loading={loading}
            onChange={handleChange}
            value={sfId}
            placeholder={organization.sfId}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Button disabled={loading || !sfId} onClick={handleSubmit} fluid>
            Update SFID
          </Button>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default OrganizationSalesforceID;
