import React, { useEffect } from 'react';
import { useAuth0 } from '../lib/auth0';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  });

  return null;
};

export default Logout;
