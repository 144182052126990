import React from 'react';
import { Loader } from 'semantic-ui-react';

import Login from './Login';
import Routes from './Routes';
import NavMenu from './NavMenu';
import EnvironmentMessage from './EnvironmentMessage';
import AuthorizedApolloProvider from './AuthorizedApolloProvider';

import { useAuth0 } from '../lib/auth0';

export default function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) return <Loader active />;
  if (!isAuthenticated) return <Login />;

  return (
    <AuthorizedApolloProvider>
      <EnvironmentMessage />
      <NavMenu />
      <Routes />
    </AuthorizedApolloProvider>
  );
}
