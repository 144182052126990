import React, { FC, useMemo } from 'react';
import {
  GetOrganizationRelationshipsQuery,
  OrganizationRelationshipScope,
  useGetOrganizationRelationshipsQuery,
} from 'generated/graphql';
import { Icon, Popup, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import AddRelationshipModal from './AddRelationshipModal';
import { notEmpty, SingularElement } from 'types';
import AddTypeAssociation from './AddTypeAssociation';
import RelationshipTypeAssocationLabel from './RelationshipTypeAssociationLabel';

interface OrganizationRelationshipListProps {
  organizationId: string;
}

type RelationshipRecord = SingularElement<
  GetOrganizationRelationshipsQuery['organization']['relationships']
>;

const relationshipSortFn = (a: RelationshipRecord, b: RelationshipRecord) => {
  if (
    !a.relationshipOwner ||
    !a.relationshipPartner ||
    !b.relationshipOwner ||
    !b.relationshipPartner
  )
    return 0;

  const nameA =
    a.scope === OrganizationRelationshipScope.RelationshipOwner
      ? a.relationshipPartner.name
      : a.relationshipOwner.name;
  const nameB =
    b.scope === OrganizationRelationshipScope.RelationshipOwner
      ? b.relationshipPartner.name
      : b.relationshipOwner.name;

  return nameA.localeCompare(nameB);
};

const OrganizationRelationshipList: FC<OrganizationRelationshipListProps> = ({
  organizationId,
}) => {
  const { data, loading, refetch } = useGetOrganizationRelationshipsQuery({
    variables: { id: organizationId },
  });

  const organization = data?.organization;

  const relationships = useMemo(() => {
    return [...(organization?.relationships ?? [])].sort(relationshipSortFn);
  }, [organization?.relationships]);

  return (
    <>
      {organization && !loading && (
        <AddRelationshipModal organization={organization} refetchFn={refetch} />
      )}
      <Table basic="very" compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>Name</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">
              Owned
            </Table.HeaderCell>
            <Table.HeaderCell>Types</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell colSpan={3}>Loading...</Table.Cell>
            </Table.Row>
          )}
          {!organization && !loading && (
            <Table.Row>
              <Table.Cell colSpan={3}>Organization not found</Table.Cell>
            </Table.Row>
          )}
          {!loading && relationships.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={3}>This organization has no relationships.</Table.Cell>
            </Table.Row>
          )}
          {relationships.map((r, idx) => {
            if (!r.relationshipPartner || !r.relationshipOwner) {
              return (
                <Table.Row key={idx}>
                  <Table.Cell negative colSpan={3}>
                    Invalid Relationship
                  </Table.Cell>
                </Table.Row>
              );
            } else {
              const isOwner = r.scope === OrganizationRelationshipScope.RelationshipOwner;
              return (
                <Table.Row key={idx}>
                  <Table.Cell>
                    {isOwner ? (
                      <Link to={`/organizations/${r.relationshipPartner.id}`}>
                        {r.relationshipPartner.name}
                      </Link>
                    ) : (
                      <>
                        <Link to={`/organizations/${r.relationshipOwner.id}`}>
                          {r.relationshipOwner.name}
                        </Link>
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {isOwner ? (
                      <Icon name="check circle outline" color="green" />
                    ) : (
                      <Popup
                        trigger={<Icon name="x" />}
                        content={`The relationship is owned by ${r.relationshipOwner.name ?? 'Unknown'}. Click name to manage on their profile.`}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {(r.relationshipTypes ?? []).map(t => (
                      <RelationshipTypeAssocationLabel
                        key={t.associationId}
                        relationshipOwnerId={organizationId}
                        relationshipPartnerId={r.relationshipPartner!.id}
                        association={t}
                        isOwner={isOwner}
                        refetchFn={refetch}
                        currentTypeIds={
                          r.relationshipTypes?.map(t => t.relationshipType?.id).filter(notEmpty) ??
                          []
                        }
                      />
                    ))}
                    {isOwner && (
                      <AddTypeAssociation
                        relationshipOwnerId={organizationId}
                        relationshipPartnerId={r.relationshipPartner!.id}
                        currentTypeIds={
                          r.relationshipTypes?.map(t => t.relationshipType?.id).filter(notEmpty) ??
                          []
                        }
                        refetchFn={refetch}
                        loading={loading}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            }
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default OrganizationRelationshipList;
