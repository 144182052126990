import React, { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import OrganizationUsersList from './OrganizationUsersList';
import OrganizationLocationsList from './OrganizationLocationsList';
import LogoUploader from './LogoUploader';
import { Container, Loader, Tab } from 'semantic-ui-react';
import Features from '../Features';
import SET_FEATURES from './queries/setFeatures.gql';
import MultifactorAuthentication from './MultifactorAuthentication';
import OrganizationReleaseToggles from './OrganizationReleaseToggles';
import OrganizationHeader from './OrganizationHeader';
import OrganizationSalesforceID from './OrganizationSalesforceID';
import { useRecentOrgs } from './utils';
import OrganizationRelationshipList from './OrganizationRelationshipList';
import { useGetOrganizationByIdQuery } from 'generated/graphql';

interface OrganizationDetailProps extends RouteComponentProps<{ id: string }> {}

const OrganizationDetail: FC<OrganizationDetailProps> = ({ match }) => {
  const { loading, data } = useGetOrganizationByIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: match.params.id },
  });

  const organization = data?.organization;

  useRecentOrgs(organization);

  if (loading) return <Loader active />;

  if (!organization) return <Redirect to="/organizations" />;

  const panes = [
    {
      menuItem: 'Locations',
      render: () => (
        <Tab.Pane>
          <OrganizationLocationsList id={organization.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Users',
      render: () => (
        <Tab.Pane>
          <OrganizationUsersList organizationId={organization.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Relationships',
      render: () => (
        <Tab.Pane>
          <OrganizationRelationshipList organizationId={organization.id} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container>
      <OrganizationHeader id={organization.id} name={organization.name} />
      <LogoUploader id={organization.id} />
      <Features id={organization.id} features={organization.features ?? []} query={SET_FEATURES} />
      <OrganizationReleaseToggles organization={organization} />
      <MultifactorAuthentication organization={organization} />
      <OrganizationSalesforceID organization={organization} />
      <Tab panes={panes} />
    </Container>
  );
};

export default OrganizationDetail;
