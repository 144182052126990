import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Auth0Provider } from './lib/auth0';
import App from './components/App';
import Layout from './components/Layout';
import history from './lib/history';
import { domain, clientId, audience } from './config/auth0.json';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

function Index() {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={appState => {
        history.push(appState?.targetUrl || window.location.pathname);
      }}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
    >
      <Layout>
        <ToastProvider>
          <Router history={history}>
            <App />
          </Router>
        </ToastProvider>
      </Layout>
    </Auth0Provider>
  );
}

const app = document.getElementById('app');
ReactDOM.render(<Index />, app);
