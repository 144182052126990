import React from 'react';
import styled from 'styled-components';
import { Header, List } from 'semantic-ui-react';
import OrganizationNameModal from './OrganizationNameModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHighlight } from 'lib/styles';

const { Icon } = List;

const SplitContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

interface Props {
  id: string;
  name: string;
}

export default function OrganizationHeader({ id, name }: Props) {
  const { highlight, HighlightContainer, setRef } = useHighlight({
    containerStyles: `
      margin-left: -5px;
      padding: 2px 5px;
    `,
  });

  return (
    <SplitContainer>
      <div>
        <Header as="h1">{name}</Header>
        <HighlightContainer ref={setRef}>{id}</HighlightContainer>{' '}
        <CopyToClipboard onCopy={highlight} text={id}>
          <Icon name="copy" link={true} />
        </CopyToClipboard>
      </div>
      <OrganizationNameModal currentName={name} id={id} />
    </SplitContainer>
  );
}
