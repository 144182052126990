import React, { FC } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Dropdown } from 'semantic-ui-react';
import { startCase } from 'lodash';
import {
  useGetRelationshipTypesQuery,
  useAddOrganizationRelationshipTypeAssociationMutation,
} from 'generated/graphql';

interface AddTypeAssociationProps {
  relationshipOwnerId: string;
  relationshipPartnerId: string;
  currentTypeIds: string[];
  refetchFn: () => void;
  loading?: boolean;
}

const AddTypeAssociation: FC<AddTypeAssociationProps> = ({
  relationshipOwnerId,
  relationshipPartnerId,
  currentTypeIds,
  refetchFn,
  loading: extLoading,
}) => {
  const { addToast } = useToasts();
  const { data: relTypeData, loading: relTypesLoading } = useGetRelationshipTypesQuery();
  const [addTypeAssociation, { loading: addTypeAssociationLoading }] =
    useAddOrganizationRelationshipTypeAssociationMutation();

  const loading = relTypesLoading || addTypeAssociationLoading || extLoading;

  const handleAddAssociation = (relationshipTypeId: string) => {
    addTypeAssociation({
      variables: {
        input: {
          relationshipOwnerId,
          relationshipPartnerId,
          relationshipTypeId,
        },
      },
      onCompleted(data) {
        const errors = data.addOrganizationRelationshipTypeAssociation?.errors;
        if (errors?.length) {
          addToast(`Error adding relationship type: ${errors.map(e => e.message).join(',')}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        refetchFn();
      },
      onError(error) {
        addToast(`Error adding relationship type: ${error.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    });
  };

  return (
    <Dropdown
      inline
      floating
      icon="add"
      className="icon"
      loading={loading}
      disabled={relTypeData?.organizationRelationshipTypes?.length === currentTypeIds.length}
    >
      <Dropdown.Menu>
        {(relTypeData?.organizationRelationshipTypes ?? [])
          .filter(t => !currentTypeIds.includes(t.id))
          .map(t => (
            <Dropdown.Item
              key={t.id}
              text={startCase(t.name ?? 'Unknown')}
              description={t.description}
              onClick={() => handleAddAssociation(t.id)}
            />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddTypeAssociation;
